import s from './afterRegistrationPromotionModal.module.css'
import image from './popup_after_registration_promotion.webp'
import { Button } from '../../../components/Button'
import { SharedProps } from '../../../components/ModalDialog/ModalDialog'
import cx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { reachGoal } from '../../../utils/metrics'
import { Trans } from 'react-i18next'
import CountdownTimer from '../../../components/new-editor/components/CountdownTimer'
import usePromocodeHook from '../../../hooks/usePromocodeHook'

export const MODAL_NAME = 'afterRegistrationPromotionModal'

export type AfterRegistrationPromotionModalProps = {
  onBuyPlanHandler: () => void
}

export function AfterRegistrationPromotionModal(props: AfterRegistrationPromotionModalProps & SharedProps) {
  const { close, onBuyPlanHandler } = props
  const { t } = useTranslation()
  const { secondsForAfterRegistrationTimer, isMonthlySubscription } = usePromocodeHook()

  const onBuyHandler = () => {
    reachGoal('click-after-registration-promotion-button')
    close()
    onBuyPlanHandler()
  }

  useEffect(() => {
    reachGoal('open-after-registration-promotion-popup')
  },[])

  const getButtonTextKey = () => {
    return isMonthlySubscription() ? 'pages.blackFridayModal.activateDiscountButtonWithPlan' : 'pages.blackFridayModal.activateDiscountButtonWithoutPlan'
  }

  return (
    <div className={s['body-block']}>
      <div className={s['timer-block']}>
        <CountdownTimer initialSeconds={secondsForAfterRegistrationTimer()}/>
      </div>
      <img className={s['body-block_image']} src={image} />
      <h1 className={cx(s['body-block_main-text'], 'titleM')}>
        <Trans 
          i18nKey='pages.blackFridayModal.blackFridayHeader'
          components={{
            b: <b className={s['body-block_main-text__blood-weight']} />
          }}
        />
      </h1>


      <div className={cx(s['minor-text-block'], 'paragraphM')}>
        <p className={s['minor-text-block_first-minor-text']}>
          <Trans 
            i18nKey='pages.afterRegistrationPromotionModal.discountOfferWithoutPlan'
            components={{
              b: <b />,
            }}
          />
        </p>
      </div>
      
      <div className={cx(s['button-block'])}>
        <Button
          className= {s['button-block_buy-button']}
          size="m"
          grow={false}
          onClick={() => {
              onBuyHandler()
          }}
        >
          {t(getButtonTextKey())}
        </Button>
      </div>
    </div>
  )
}
