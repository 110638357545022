import heic2any from 'heic2any'

export const resizeImage = (file: File, maxSize: number): Promise<File> => {
  return new Promise(async (resolve, reject) => {
    try {
      let inputFile = file

      if (isHeicFile(file)) {
        inputFile = await convertHeicToJpeg(file)
      }

      const reader = new FileReader()
      reader.readAsDataURL(inputFile)

      reader.onload = (event) => {
        const img = new Image()
        img.src = event.target?.result as string

        img.onload = () => {
          const { width, height } = img

          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          const scale = Math.min(maxSize / width, maxSize / height, 1)

          canvas.width = width * scale
          canvas.height = height * scale
          ctx?.drawImage(img, 0, 0, canvas.width, canvas.height)

          canvas.toBlob(
            (blob) => {
              if (blob) {
                resolve(
                  new File([blob], `${inputFile.name.split('.')[0]}.webp`, {
                    type: 'image/webp',
                  })
                )
              } else {
                reject(new Error('Canvas is empty'))
              }
            },
            'image/webp'
          )
        }

        img.onerror = (error) => reject(error)
      }

      reader.onerror = (error) => reject(error)
    } catch (error) {
      reject(error)
    }
  })
}

const isHeicFile = (file: File) =>
  file.type === 'image/heic' ||
  file.type === 'image/heif' ||
  file.name.toLowerCase().endsWith('.heic') ||
  file.name.toLowerCase().endsWith('.heif')

export async function convertHeicToJpeg(file: File): Promise<File> {
  const convertedResult = await heic2any({
    blob: file,
    toType: 'image/jpeg',
  })

  let convertedBlob: Blob

  if (Array.isArray(convertedResult)) {
    convertedBlob = convertedResult[0]
  } else {
    convertedBlob = convertedResult
  }

  return new File([convertedBlob], `${file.name.split('.')[0]}.jpeg`, {
    type: 'image/jpeg',
  })
}

export const file2Blob = (file: File): Blob => new Blob([file], { type: file.type })
export const blob2File = (blob: Blob, fileName: string) =>
  new File([blob], fileName, { type: blob.type })

export const resizeFromBlob = async (blobData: Blob, size: number): Promise<Blob> => {
  const f = blob2File(blobData, 'file.webp')

  const image = await resizeImage(f, size)

  return file2Blob(image)
}
