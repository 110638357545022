import {Button} from "../../../Button";
import {reachGoal} from "../../../../utils/metrics";
import SidebarTabs from "../../components/SidebarTabs";
import React, {MutableRefObject, useEffect, useState} from "react";
import {PanelState, PromptRef} from "./types";
import {StoreType} from "polotno/model/store";
import {useModalManager} from "../../../../store/useModalManager";
import { ReactComponent as ArrowLeft } from '@material-design-icons/svg/round/chevron_left.svg'
import { ReactComponent as AutoFix } from '@material-design-icons/svg/round/auto_fix_high.svg'
import { ReactComponent as HeaderStar } from '../../header_star.svg'
import { ReactComponent as DollarSymbol } from '../../dollar_symbol.svg'
import { ReactComponent as UpdateAiBackground } from '../../update_ai_backgrounds.svg'
import {
    applyBackground,
    BackgroundType,
    createProjectStatParams,
    getMainObjectBlob
} from "../../utils/polotno.utils.js";
import {Card, LOADING_IMG, useCards} from "../../aicard/aiCardsContext";
import {usePolotno} from "../../../../store/usePolotno";
import cx from 'clsx'
import {updateCredits} from "../../../../api/updateCredits";
import {getBackgroundGenerator, setCustomStoreEntityProps} from "../../utils/image.utils";
import {MODAL_NAME as CREDITS_MODAL} from "../../../../ds/modal/BuyCreditsModal";
import {useImageStore} from "../../../../store/useImageStore";
import {useCredits} from "../../../../store/useCredits";
import {useFullPageLoading} from "../../../../contexts/FullPageLoadingContext";
import {hasCredits} from "../../utils/subscription.utils";
import {useSelectedBackgrounds} from "../../../../store/useSelectedBackgrounds";
import {getNewCard, getPreviouslySelected, isActiveCard, upscaleAndApplyBackground} from "../../utils/card.utils";
import {templateCategories, TemplateCategory} from "../../aicard/aitemplate";
import { AICard } from "../../aicard/aicard";
import { useTranslation } from 'react-i18next'
import { useClosePanelInMobile } from '../../../../hooks/useClosePanelInMobile'
import {GoToMultiAsset} from "./GoToMultiAsset";
import {useAuthContext} from "../../../../contexts/AuthProvider";
import {PositionChanged} from "../../../PositionChanged";
import {useEditorProductPositionChangeState} from "../../../../store/useEditorProductPositionChangeState";

type Props = {
    setPanelState: (p: PanelState) => void,
    store: StoreType,
    isScreenDesktop: boolean,
    activeBackground: any,
    setActiveBackground: any,
    promptRef: MutableRefObject<PromptRef>
}

const MINIMALISM = 0;
const REALISM = 1;
const CARDS_PER_TAB = 4

const getTabCards = (cards: Card[], iS_MINIMALISM: boolean) => iS_MINIMALISM
    ? cards.slice(0,CARDS_PER_TAB)
    : cards.slice(CARDS_PER_TAB, CARDS_PER_TAB * 2)


export const TemplatesPanel = ({store, activeBackground, setActiveBackground, setPanelState, isScreenDesktop, promptRef}: Props) => {
    const toggleModal = useModalManager((s) => s.toggleModal)
    const { cards, setBackgrounds, setCardBackgroundUpdating, refreshCounter } = useCards()
    const [selectedAIBackgroundTab, setSelectedAIBackgroundTab] = useState(REALISM)
    const product = usePolotno(s => s.product)
    const [isAnimatedGenerationButton, setIsAnimatedGenerationButton] = useState(0)
    const hasPositionChanged = useEditorProductPositionChangeState(s => s.hasChanged)
    const imageResultStore = useImageStore()
    const { credits, setCredits } = useCredits();
    const { user } = useAuthContext()
    const { setLoading } = useFullPageLoading()
    const selectedBackgrounds = useSelectedBackgrounds((s) => s.selectedBg)
    const selectBackground = useSelectedBackgrounds((s) => s.addBackgroundPair)
    const { t } = useTranslation()
    const closePanelInMobile = useClosePanelInMobile()
    const [isMultiAssetHintShown, showMultiAssetHint] = useState(false)

    const maybeLoadCard = (startIndex: number) => (card: Card, index: number) =>
        (index >= startIndex && index < startIndex + CARDS_PER_TAB ? setCardBackgroundUpdating(card) : card)

    useEffect(() => {
        const index = isMinimalism ? 0 : CARDS_PER_TAB
        if (cards[index]?.dataUrl === LOADING_IMG && !cards[index]?.loading) {
            updateCards()
        }
    }, [cards.length, refreshCounter])
    const onTabChange = (newTab: number) => {
        const nextTabCards = getTabCards(cards, newTab === MINIMALISM)
        if (nextTabCards[0] && nextTabCards.every(card => !card.meta && ! card.updating)) {
            updateCards(nextTabCards, newTab)
        }
    }

    const animateNext = () => setIsAnimatedGenerationButton((prevKey) => prevKey + 1)
    
    const isMinimalism = selectedAIBackgroundTab === MINIMALISM
    const tabCards = getTabCards(cards, isMinimalism)
    const onBackButtonClick = () =>{
        closePanelInMobile()
    }
    const goToPrompts = () => {
        setPanelState(PanelState.Prompt)
        reachGoal('click_custom_background')
    }

    const generateBackground = getBackgroundGenerator({store, imageResultStore})

    const generateAndApplyBackground = async (
        prompt: string,
        negativePrompt: string,
        name?: string,
        selectAiService: boolean = false
    ) => {
        if (hasCredits(credits)) {
            setLoading(true)
            setCustomStoreEntityProps(store, { currentPromptInStore: {prompt, negativePrompt}})
            setCustomStoreEntityProps(product, {currentPromptInStore: {prompt, negativePrompt}})
            const blob = await getMainObjectBlob(store, product)
            const res = await generateBackground(blob, prompt, negativePrompt, { selectAiService })
            if (res.imageUrl) {
                setCustomStoreEntityProps(store, { imageUrl: res.imageUrl })
                applyBackground(store, res.imageUrl)
                showMultiAssetHint(true)
            } else console.error('Bad image url', res)
            if(res.savedMaskUrl) setCustomStoreEntityProps(product, { maskUrl: res.savedMaskUrl})
            if(res.credits){
                updateCredits(res.credits,setCredits)
                reachGoal("user_spent_credit", createProjectStatParams(store, product))
            }
            setLoading(false)
        } else if (credits !== null || credits == 0) {
            toggleModal(CREDITS_MODAL, true)
        }
    }

    const setActiveCard = (thumb: string, bg: string | null | undefined) => {
        if (thumb) selectBackground(thumb, bg ?? '')
    }

    const applyAiBackground = async (card: Card) => {
        if (card.loading) return
        const {meta, dataUrl} = card
        const isCobalt = meta && meta.srv === 'cobalt'
        const imageUrl = isCobalt ? meta.thumbUrl : dataUrl
        if (hasCredits(credits)) {
            setLoading(true)
            if (isActiveCard(imageUrl, selectedBackgrounds) || !card.dataUrl) {
                const mp = getPreviouslySelected(imageUrl, selectedBackgrounds)
                if (mp?.bg) applyBackground(store, mp.bg)
                else await upscaleAndApplyBackground(store, card)
            } else {
                const resp = await upscaleAndApplyBackground(store, card, (bg: string) => { setActiveCard(imageUrl, bg) })
                if (resp?.credits) updateCredits(resp.credits, setCredits)
                reachGoal("user_spent_credit", createProjectStatParams(store, product))
            }
            setLoading(false)
            imageResultStore.setCurrentByImageUrl(imageUrl)
            reachGoal(`click_suggested_background`)
            promptRef.current = {prompt: card.prompt, negativePrompt: ''}
            setCustomStoreEntityProps(store, {currentPromptInStore: {}})
            setCustomStoreEntityProps(product, {currentPromptInStore: {...promptRef.current}, maskUrl: card.meta?.maskImageUrl})
            showMultiAssetHint(true)
        } else toggleModal(CREDITS_MODAL, true)
    }

    async function updateCards(targetCards: Card[] = tabCards, tab = selectedAIBackgroundTab) {
        const isMinimalism = tab === MINIMALISM
        if (cards.length === 0) return
        setBackgrounds((currentCards: any) => {
            const startIndex = isMinimalism ? 0 : CARDS_PER_TAB;
            return [...currentCards.map(maybeLoadCard(startIndex))]
        })
        const blob = await getMainObjectBlob(store, product)
        const generateOptions = {isSuggested: true}
        const promises = targetCards.map(async (card, index) => {
            card.loading = true
            if (!card.prompt) return
            try {
                await store.waitLoading()
                const {imageUrl, meta, srv} = await generateBackground(blob, card.prompt, '', generateOptions)
                const actualIndex = isMinimalism ? index : cards.length - CARDS_PER_TAB + index;
                setBackgrounds((currentCards) => {
                    const newCards = [...currentCards]
                    newCards[actualIndex] = getNewCard(imageUrl, meta, newCards[actualIndex], srv)
                    return newCards
                })
                if (actualIndex + 1000 == activeBackground) {
                    setCustomStoreEntityProps(store, {cardInStore: card})
                }
            } catch (error) {
                console.error('Error fetching data URL:', error)
            }
        })
        await Promise.all(promises)
    }


    return (
        <>
            <div className="tool-panel_content">
                <div className="tool-panel_content_text">
                    <div className='backButton' onClick={() => onBackButtonClick()}>
                        <ArrowLeft />
                    </div>
                    <h2 className={`${isScreenDesktop ? 'titleS' : 'bodyL'} colorTextBlackPrimary`}>{t('pages.editor.aiBackground')}</h2>
                    <Button className='createBackgroundDesktop'
                            size="s"
                            variation="tertiary"
                            grow={false}
                            onClick={goToPrompts}
                    >
                        {t('pages.editor.createCustomBackground')}
                    </Button>
                    <div className='createBackgroundMobile' onClick={goToPrompts} >
                        <AutoFix />
                    </div>
                </div>
                {isMultiAssetHintShown && !user?.hideMultiAssetHint && !hasPositionChanged &&  <GoToMultiAsset store={store} crossClick={() => showMultiAssetHint(false)} />}
                <div className={cx("tool-panel_content_wrapper", "content_AI_wrapper", hasPositionChanged && "tool-panel_content_wrapper--shifted")} style={{marginBottom: '100px'}}>
                    <div className="tool-panel_content_suggested">
                        <div className="tool-panel_header_line">
                            <div className="tool-panel_header_and_star">
                                <h3 className="bodyM colorAccentVioletDark">{t('pages.editor.perfectBackgrounds')}</h3>
                                <HeaderStar style={{ position: 'absolute', right: '-15px', top: '-5px' }} />
                            </div>
                            <div
                                className={`tool-panel_update_content_suggested_button ${tabCards.length != 4 || tabCards.some(card => card.loading) ? 'disable' : ''}`}
                                onClick={() => {
                                    reachGoal('click_update_AI_template_background')
                                    if (tabCards.length != 4 || (tabCards.every(card => !card.loading))) updateCards()
                                }}
                            >
                                <UpdateAiBackground />
                            </div>
                        </div>

                        <SidebarTabs
                            tabs={[t('pages.editor.minimalism'), t('pages.editor.realism')]}
                            onTabClick={(value) => {
                                setSelectedAIBackgroundTab(value)
                                onTabChange(value)
                            }}
                            value={selectedAIBackgroundTab}
                        />

                        <div className="tool-panel_content_category_suggested_templates">
                            {tabCards.map((card, index) => {
                                const actualIndex = isMinimalism ? index : cards.length - CARDS_PER_TAB + index;
                                return (
                                    <AICard
                                        cardSize={store.custom.cardSize}
                                        previewKey={actualIndex + 1000}
                                        key={`ai-card-${actualIndex}`}
                                        activeBackgroundKey={activeBackground}
                                        setActiveBackgroundKey={setActiveBackground}
                                        cardItem={card}
                                        onClick={() => {
                                            setCustomStoreEntityProps(store, { cardInStore: card })
                                            animateNext()
                                            reachGoal('click_AI_template_background')
                                        }}
                                    />
                                )
                            })}
                            {cards.length === 0 && [... new Array(4)].map((_, idx) => (
                                <AICard
                                    key={idx + 1000}
                                    cardSize={store.custom.cardSize}
                                    previewKey={idx + 1000}
                                    activeBackgroundKey={activeBackground}
                                    setActiveBackgroundKey={setActiveBackground}
                                    cardItem={{ loading: false, prompt: '', title: '', dataUrl: '/image_loading.svg' }}
                                    onClick={() => { }}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="tool-panel_content_templates">
                        <h3 className="bodyL colorTextBlackPrimary">{t('pages.editor.aiBackgroundTemplates')}</h3>
                        {templateCategories.map((category: any, index) => (
                            <TemplateCategory
                                key={index}
                                name={category.name}
                                templates={category.templates}
                                generateBackground={generateAndApplyBackground}
                                setActiveBackgroundKey={setActiveBackground}
                                activeBackgroundKey={activeBackground}
                                isStartAnimationOnGenerationButton={animateNext}
                            />
                        ))}
                    </div>
                </div>
                <div className="tool-panel_content_category_warning">
                        <div
                            key={isAnimatedGenerationButton}
                            className={cx('position-warning-button', !activeBackground && 'disable', isAnimatedGenerationButton != 0 && 'animate_generate_button')}
                        >
                            <Button
                                className="warning-button"
                                size="m"
                                onClick={() => {
                                    if (activeBackground) {
                                        if (activeBackground < 1000) {
                                            setCustomStoreEntityProps(product, { activeBackgroundType: BackgroundType.Template })
                                            generateAndApplyBackground(
                                                store.custom.currentPromptInStore.prompt,
                                                store.custom.currentPromptInStore.negativePrompt,
                                                '',
                                                false
                                            )
                                        } else {
                                            setCustomStoreEntityProps(product, { activeBackgroundType: BackgroundType.Suggested })
                                            setCustomStoreEntityProps(store, { cardInStore: cards[activeBackground - 1000] })
                                            applyAiBackground(store.custom.cardInStore)
                                        }
                                    }
                                    user?.hideMultiAssetHint && closePanelInMobile()
                                    reachGoal('click_adapt_background')
                                }}
                            >
                                <div className="warning-button-label">
                                    {!activeBackground
                                        ? t('pages.editor.generateButton')
                                        : store.activePage.background.includes('/') &&
                                        !store.activePage.background.includes('http') &&
                                        activeBackground < 1000
                                            ? t('pages.editor.generateNew')
                                            : activeBackground < 1000
                                                ? t('pages.editor.generateButton')
                                                : t('pages.editor.applyBackground')}
                                    {(!activeBackground ||
                                        activeBackground < 1000 ||
                                        !isActiveCard(cards[activeBackground - 1000]?.dataUrl, selectedBackgrounds)) && (
                                        <div className="warning-button-label-price">
                                            <DollarSymbol style={{ marginTop: '-2px' }} />
                                            <span>1</span>
                                        </div>
                                    )}
                                </div>
                            </Button>
                        </div>
                   </div>
            </div>
            <PositionChanged store={store}/>
        </>
    )
}