import { CleanLayout } from '../components/layouts/CleanLayout'
import Header from '../components/Header/Header'
import { useFullPageLoading } from '../contexts/FullPageLoadingContext'
import React, { useEffect, useState } from 'react'
import { backendUrl } from '../config/app'
import {Subscription, useSubscriptions} from "../store/useSubscriptions";
import {ModalDialog} from "../components/ModalDialog/ModalDialog";
import {DEMO_PLAN_MODAL, DemoPlanModal} from "../ds/modal/DemoPlanModal";
import {useModalManager} from "../store/useModalManager";
import {fetchActiveSubscriptions} from "../api/fetchActiveSubscriptions";

type DemoPlanResult =
  | { success: true; subscription: Subscription }
  | { success: false; status: string }

export function DemoPlanPage() {
  const { setLoading } = useFullPageLoading()
  const setActiveSubscriptions = useSubscriptions((s) => s.setActiveSubscriptions)

  const [success, setSuccess] = useState(false)
  const toggleModal = useModalManager((s) => s.toggleModal)

  const addDemoPlan = async () => {
    setLoading(true)
    try {
      const result: DemoPlanResult = await fetch(
          backendUrl() + 'api/ngb/add-demo',
          {method:'post'},
      ).then((res) =>
        res.json()
      )
      setLoading(false)
      if(result.success){
        const subscriptions = await fetchActiveSubscriptions()
        setActiveSubscriptions(subscriptions)
      }
      toggleModal(DEMO_PLAN_MODAL,true)
      setSuccess(result.success)
    } catch (e) {
      setLoading(false)
      toggleModal(DEMO_PLAN_MODAL,true)
    }
  }

  useEffect(() => {
    addDemoPlan()
  }, [])

  return <CleanLayout header={<Header />}>
    <ModalDialog name={DEMO_PLAN_MODAL} withCloseButton={false} closeable={false} render={(p) => <DemoPlanModal {...p} isSuccess={success} />} />
  </CleanLayout>
}
