export const polotnoRussianTranslations = {
  toolbar: {
    copyStyle: 'Копировать стиль',
    aiText: {
      continue: 'Продолжить писать',
      aiWrite: 'AI текст',
      formal: 'Формальный',
      friendly: 'Дружеский',
      humorous: 'С юморком',
      professional: 'Профессиональный',
      proofread: 'Проверить написание',
      rewrite: 'Переписать',
      shorten: 'Сократить',
      tones: 'Тон речи',
      customPrompt: 'Свой запрос',
      generatedResult: 'Сгенерированный результат',
      cancel: 'Отмена',
      generate: 'Сгенерировать',
      back: 'Назад',
      tryAgain: 'Попробовать снова',
      insert: 'Вставить',
      promptPlaceholder: 'Опишите, что вы хотите сгенерировать',
    },
    duration: 'Длительность',
    opacity: 'Непрозрачность',
    effects: 'Эффекты',
    blur: 'Размытие',
    textBackground: 'Фон текста',
    backgroundCornerRadius: 'Радиус углов фона',
    backgroundOpacity: 'Непрозрачность фона',
    backgroundPadding: 'Отступ фона',
    brightness: 'Яркость',
    sepia: 'Сепия',
    grayscale: 'Оттенки серого',
    textStroke: 'Обводка текста',
    shadow: 'Тень',
    border: 'Граница',
    cornerRadius: 'Радиус углов',
    position: 'Позиция',
    layering: 'Слои',
    toForward: 'Передний план',
    up: 'Вверх',
    down: 'Вниз',
    toBottom: 'Задний план',
    alignLeft: 'Левый край',
    alignCenter: 'Центр',
    alignRight: 'Правый край',
    alignTop: 'Верхний край',
    alignMiddle: 'Середина',
    alignBottom: 'Нижний край',
    flip: 'Отразить',
    flipHorizontally: 'Отразить по горизонтали',
    flipVertically: 'Отразить по вертикали',
    fitToBackground: 'Подогнать по размеру фона',
    removeBackground: 'Удалить фон',
    removeBackgroundTitle: 'Удалить фон изображения',
    cancelRemoveBackground: 'Отменить',
    confirmRemoveBackground: 'Подтвердить',
    crop: 'Обрезать',
    cropDone: 'Готово',
    cropCancel: 'Отмена',
    clip: 'Применить маску',
    removeClip: 'Удалить маску',
    removeMask: 'Удалить маску',
    transparency: 'Прозрачность',
    lockedDescription: 'Объект заблокирован. Разблокируйте его, чтобы внести изменения на холсте.',
    unlockedDescription:
      'Объект разблокирован. Заблокируйте его, чтобы предотвратить изменения на холсте.',
    removeElements: 'Удалить элементы',
    duplicateElements: 'Дублировать элементы',
    download: 'Скачать',
    saveAsImage: 'Сохранить как изображение',
    saveAsPDF: 'Сохранить как PDF',
    lineHeight: 'Высота строки',
    letterSpacing: 'Межбуквенный интервал',
    offsetX: 'Смещение X',
    offsetY: 'Смещение Y',
    color: 'Цвет',
    selectable: 'Выбираемый',
    draggable: 'Перемещаемый',
    removable: 'Удаляемый',
    resizable: 'Изменяемый размер',
    contentEditable: 'Изменяемый контент',
    styleEditable: 'Изменяемый стиль',
    alwaysOnTop: 'Всегда сверху',
    showInExport: 'Отображать в экспорте',
    ungroupElements: 'Разгруппировать',
    groupElements: 'Группировать',
    lineSize: 'Толщина линии',
    fade: 'Исчезновение',
    move: 'Перемещение',
    zoom: 'Масштабирование',
    animate: 'Анимация',
    rotate: 'Поворот',
    none: 'Нет',
    bounce: 'Прыжок',
    blink: 'Мерцание',
    spaceEvenly: 'Распределить равномерно',
    horizontalDistribution: 'По горизонтали',
    verticalDistribution: 'По вертикали',
    strokeWidth: 'Ширина обводки',
    colorPicker: {
      solid: 'Сплошной',
      linear: 'Градиент',
    },
  },
  workspace: {
    noPages: 'Пока нет страниц...',
    addPage: 'Добавить страницу',
    removePage: 'Удалить страницу',
    duplicatePage: 'Дублировать страницу',
    moveUp: 'Переместить вверх',
    moveDown: 'Переместить вниз',
  },
  pagesTimeline: {
    pages: 'Слайды',
    removePage: 'Удалить',
    addPage: 'Добавить страницу',
    duplicatePage: 'Дублировать',
    removeAudio: 'Удалить аудио',
  },
  scale: {
    reset: 'Сбросить',
  },
  error: {
    removeBackground: 'Упс! Что-то пошло не так. Фон не может быть удален.',
  },
  sidePanel: {
    templates: 'Шаблоны',
    searchTemplatesWithSameSize: 'Показать шаблоны с таким же размером',
    searchPlaceholder: 'Поиск...',
    otherFormats: 'Другие форматы',
    noResults: 'Нет результатов',
    error: 'Не удалось загрузить...',
    text: 'Текст',
    uploadFont: 'Загрузить шрифт',
    myFonts: 'Мои шрифты',
    photos: 'Фотографии',
    videos: 'Видео',
    animations: 'Анимации',
    effects: 'Эффекты',
    elements: 'Элементы',
    shapes: 'Фигуры',
    lines: 'Линии',
    upload: 'Загрузить',
    uploadImage: 'Загрузить изображение',
    uploadTip: 'Хотите загрузить свои изображения?',
    background: 'Фон',
    resize: 'Изменить размер',
    layers: 'Слои',
    layerTypes: {
      image: 'Изображение',
      text: 'Текст',
      svg: 'SVG',
      line: 'Линия',
      figure: 'Фигура',
      group: 'Группа',
    },
    layersTip: 'Элементы на активной странице:',
    noLayers: 'На странице нет элементов...',
    namePlaceholder: 'Введите имя элемента...',
    useMagicResize: 'Использовать магический размер',
    clipImage: 'Маскировать изображение',
    width: 'Ширина',
    height: 'Высота',
    magicResizeDescription:
      'Магический размер автоматически изменит размер и переместит все элементы на холсте',
    headerText: 'Заголовок',
    createHeader: 'Создать заголовок',
    subHeaderText: 'Подзаголовок',
    createSubHeader: 'Создать подзаголовок',
    bodyText: 'Текст',
    createBody: 'Создать текст',
  },
}
