import s from './invoiceTextBlock.module.css'
import { reachGoal } from '../../../utils/metrics'

export const InvoiceTextBlock = () => {
  return(
    <div className={s['invoice-text-blok']}>
      <div className={s['invoice-text-blok_text']}>
        Для выставления счета с юридического лица свяжитесь с нами в <a
          className={s['invoice-text-blok__blue']}
          onClick={() => reachGoal('click-telegram-button-get-invoice')}
          href="https://t.me/mpcardai_support?text=%D0%AF%20%D1%85%D0%BE%D1%87%D1%83%20%D0%BF%D1%80%D0%BE%D0%B8%D0%B7%D0%B2%D0%B5%D1%81%D1%82%D0%B8%20%D0%BE%D0%BF%D0%BB%D0%B0%D1%82%D1%83%20%D0%BF%D0%BE%20%D1%81%D1%87%D0%B5%D1%82%D1%83"
          target="_blank"
          rel="noopener noreferrer"
        >Telegram.</a>
      </div>
    </div>
  )
}

export default InvoiceTextBlock