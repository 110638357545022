import { axiosInstance } from "./axiosConfig"
import { backendUrl } from '../config/app'

/*
Expects

{
  "status": "pending",
  "confirmation": {
    "type": "string",
    "confirmation_url": "string"
  }
}

*/

export const initSubscription = async (planId: string, promocode?: string): Promise<string> => {
  const url = promocode
    ? `${backendUrl()}api/ngb/payments/init-subscription?plan=${planId}&promocode=${promocode}`
    : `${backendUrl()}api/ngb/payments/init-subscription?plan=${planId}`
  const response = await axiosInstance.post(url)
  if (response?.data.confirmation && response?.data.confirmation.confirmation_url) {
    return response.data.confirmation.confirmation_url
  } else {
    throw new Error('Cannot initialize payment process')
  }
}
