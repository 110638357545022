import { create } from 'zustand'

type XYCoordinate = {
  x: number
  y: number
}

export type State = {
  /**
   * ⚠️ Manually
   * This flag can be sued for manual logic
   */
  hasChanged: boolean
  /**
   * Position snapshot has took at the AI bg generation stage
   */
  position: XYCoordinate
}

export type Actions = {
  setValue: (v: boolean) => void
  setPosition: (c: XYCoordinate) => void
}

export const useEditorProductPositionChangeState = create<State & Actions>()((set) => ({
  hasChanged: false,
  position: {
    x: 0,
    y: 0,
  },
  setValue: (val: boolean) =>
    set((state) => ({
      ...state,
      hasChanged: val,
    })),
  setPosition: (val: XYCoordinate) =>
    set((state) => ({
      ...state,
      position: val,
    })),
}))
