import { axiosInstance } from '../axiosConfig'
import type { ProjectObject, State, Meta } from '../../domain/types/Project'
import { backendUrl } from '../../config/app'
import { mapProjectToObject } from './mappers'

export const duplicateProject = async (id: string): Promise<ProjectObject> => {
  const response = await axiosInstance.post(`${backendUrl()}api/ngb/projects/duplicate/${id}`)

  return mapProjectToObject(response.data)
}
