import s from './modal.module.css'
import { Button } from '../../components/Button'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import cx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Toggle } from '../../components/new-editor/components/Toggle'
import style from './confirmationInfographicsModal.module.css'
import { useAddInfographics } from '../../store/useAddInfographics'

export const MODAL_NAME = 'confirmationInfographic'

export type ConfirmationInfographicModalProps = {
  onConfirmationInfographics: (toALl:boolean) => void
}

export function ConfirmationInfographicModal(
  props: ConfirmationInfographicModalProps & SharedProps
) {
  const { close, onConfirmationInfographics } = props
  const { t } = useTranslation()
  const showConfirmationModal = useAddInfographics((s) => s.showConfirmationInfographicsModal)
  const setShowConfirmationInfographicsModal = useAddInfographics(
    (s) => s.setShowConfirmationInfographicsModal
  )

  const OnConfirmationHandler = (toAll: boolean) => {
    onConfirmationInfographics(toAll)
  }

  const handleToggleChange = () => {
    setShowConfirmationInfographicsModal(!showConfirmationModal)
    localStorage.setItem('hideConfirmationInfographicsModal_v2', showConfirmationModal.toString())
  }

  return (
    <div>
      <h1 className={cx(s.mainTextPopup, 'titleM')}>
        {t('pages.confirmationInfographicModal.applyTemplate')}
      </h1>
      <div className={cx(s.minorTextPopup, style.minorTextPopupInConfirmation, 'paragraphM')}>
        <p>{t('pages.confirmationInfographicModal.confirmTemplateChange')}</p>
      </div>
      <Toggle
        toggleChecked={!showConfirmationModal}
        handleToggleChange={handleToggleChange}
        labelText={t('pages.confirmationInfographicModal.dontAskAgain')}
      />
      <div className={s.popupButtonsVertical}>
        <Button
          size="m" grow={true}
          onClick={() => {
            close()
            OnConfirmationHandler(false)
          }}
        >
            {t('pages.confirmationInfographicModal.applyOne')}
        </Button>
        <Button
          size="m" grow={true} variation="tertiary"
          onClick={() => {
            close()
            OnConfirmationHandler(true)
          }}
        >
            {t('pages.confirmationInfographicModal.applyAll')}
        </Button>
        <Button size="m" variation="secondary" className={s.cancelButton} grow={true} onClick={close}>
          {t('pages.confirmationInfographicModal.cancelAction')}
        </Button>
      </div>
    </div>
  )
}
