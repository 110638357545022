import s from './apiAccessBlock.module.css'
import { ReactComponent as MPCardIcon } from './api_mpcard.svg'
import { ReactComponent as AIIcon } from './api_ai.svg'
import { ReactComponent as B2BIcon } from './api_b2b.svg'
import { ReactComponent as ContactIcon } from './api_contact.svg'
import { reachGoal } from '../../../../utils/metrics'
import { Button } from '../../../Button'

export const ApiAccessBlock = () => {
  return(
    <div className={s['api-access-blok']}>
      <div className={s['api-access-blok_text']}>
        <div className={s['api-access-blok_text_main']}>
          API-доступ и массовая обработка
        </div>
        <div className={s['api-access-blok_text_minor']}>
          Подключайтесь к нашему API, чтобы автоматизировать создание карточек для маркетплейсов. Массовое удаление фона, цветокоррекция, AI-генерация фонов и инфографики — теперь доступны для интеграции в ваши процессы.
        </div>
      </div>
      <div className={s['api-access-blok_contacts']}>
        <div className={s['api-access-blok_contacts_icons']}>
          <MPCardIcon />
          <AIIcon />
          <B2BIcon />
        </div>
        <a
          className={s['api-access-blok_contacts_button']}
          onClick={() => reachGoal('click-telegram-button-get-api')}
          href="https://t.me/mpcardai_support?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82%20%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%20%D0%BA%20API%20%D0%BC%D0%B0%D1%81%D1%81%D0%BE%D0%B2%D0%BE%D0%B9%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8%20MPCard"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ContactIcon />
          <div className={s['api-access-blok_contacts_button_text']}>
            Свяжитесь с нами
          </div>
        </a>
      </div>
    </div>
  )
}

export default ApiAccessBlock;