import { Project, ProjectObject } from '../../domain/types/Project'

export const mapProjectToObject = (p: Project): ProjectObject => ({
  ...p,
  state: p.state ? JSON.parse(p.state) : {},
  meta: p.meta ? JSON.parse(p.meta) : null,
})

export const mapProjectsToObjects = (p: Project[]): ProjectObject[] =>
  p.map((i) => mapProjectToObject(i))
