import { create } from 'zustand'
import { getPromotionAnalyticsEvent } from '../components/new-editor/utils/promotion.utils'
import { useAuthContext } from '../contexts/AuthProvider'

type PromotionState = {
  isSet: boolean | null
  startDate: Date | null
}

type PromotionActions = {
  setIsSet: (val: boolean) => void
  setStartDate: (val: Date) => void
  initializeIsSet: (userId: number) => void;
}

export const usePromotion = create<PromotionState & PromotionActions>((set) => ({
  isSet: null,
  startDate: new Date(),
  setIsSet: (val: boolean) =>
    set(() => ({
      isSet: val,
    })),
  setStartDate: (val: Date) =>
    set(() => ({
      startDate: val,
    })),
  initializeIsSet: async (userId: number) => {
    try {
      const result = await getPromotionAnalyticsEvent(userId);
      set({ isSet: result.length > 0 });
      if (result.length > 0 && result[0]._max) {
        set({ startDate: new Date(result[0]._max.created_at) });
      } else {
        set({ startDate: new Date() });
      }
    } catch (error) {
      console.error('Error:', error);
      set({ isSet: false });
    }
  },
}));