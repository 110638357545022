import { create } from 'zustand'
import { Feature } from '../components/plans/cards/CardPlanRedesign'

export type CardData = {
  first6?: string
  expiry_year: string
  expiry_month: string
  card_type: string
}

export type SubscriptionType = 'MONTHLY' | 'ANNUAL'

export const subscriptionTiers = ['standard', 'premium', 'business'] as const 
export type SubscriptionTierType = typeof subscriptionTiers[number];

export const DEMO_PLAN_ID = 'c15e696e-603a-45ab-8ed1-07ac4c1fb4e1'

export type PlanDetails = {
  id: string
  // try to avoid using name property because of its internal
  //name: string
  title: string
  tier_type: SubscriptionTierType,
  type: SubscriptionType
  price: number
  credit_amount: number
  features_list: Array<Feature>
}

export type Subscription = {
  id: string
  userId: string
  planId: string
  subscriptionType: SubscriptionType
  startDate: string
  nextPaymentDate: string
  price: number
  creditsPerPeriod: number // -1 is possible
  cardData?: CardData | null
  planDetails: PlanDetails
  cancelledAt: string
}

export const PaywallVariations = {
  createMore: 'project',
  createMorePremium: 'project_10',
  createMoreBusiness: 'project_100',
  backgrounds: 'backgrounds',
  addImage: 'add_image',
  templates: 'templates',
  stickers: 'figures',
  addText: 'text',
  adjustSize: 'resize',
  download: 'download',
  multiAsset: 'multi_asset'
}

export type Variation = typeof PaywallVariations[keyof typeof PaywallVariations]

export type SubscriptionState = {
  activeSubscriptions: Subscription[],
  hasActiveSubscription: boolean,
  paywallVariation: Variation | null,
  hasPremiumSubscription: boolean,
  hasBusinessSubscription: boolean,
}

export type SubscriptionActions = {
  setActiveSubscriptions: (sbs: Subscription[]) => void,
  setPaywallVariation: (paywallVariation: Variation) => void
}

const BusinessSubscription = 'business'
const premiumSubscriptions = ['premium', BusinessSubscription]


export const useSubscriptions = create<SubscriptionState & SubscriptionActions>()((set) => ({
  activeSubscriptions: [],
  hasActiveSubscription: false,
  hasPremiumSubscription: false,
  hasBusinessSubscription: false,
  paywallVariation: null,
  setActiveSubscriptions: (sbs: Subscription[]) =>
    set((state) => ({
      ...state,
      activeSubscriptions: sbs,
      hasActiveSubscription: sbs.length > 0,
      hasPremiumSubscription:
        sbs.filter(subscription => premiumSubscriptions.includes(subscription.planDetails.tier_type)).length > 0,
      hasBusinessSubscription:
        !!sbs.find(subscription => BusinessSubscription === subscription.planDetails.tier_type)
    })),
  setPaywallVariation: (paywallVariation: Variation) =>
    set((state) => ({
      ...state,
      paywallVariation,
    }))
}))

