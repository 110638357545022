import type { ProjectObject } from '../../domain/types/Project'
import { backendUrl } from '../../config/app'
import { mapProjectsToObjects } from './mappers'
import { axiosInstance } from '../axiosConfig'

export const fetchProjects = async (): Promise<ProjectObject[]> => {
  const response = await axiosInstance.get(`${backendUrl()}api/ngb/projects`)

  return mapProjectsToObjects(response.data).sort((a, b) => {
    const p1 = new Date(a.updated_at).getTime()
    const p2 = new Date(b.updated_at).getTime()
    return p2 - p1
  })
}
