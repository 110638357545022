import { FullWideLayout } from '../components/layouts/FullWideLayout'
import Header from '../components/Header/Header'
import { useNavigate } from 'react-router-dom'
import {useContext} from "react";
import {FeatureFlagContext} from "../contexts/FeatureFlagContext";
import {PlansContainerRedesign} from "../components/plans/PlansContainerRedesign";
import {useResize} from "../hooks/useResize";

export function PlansPage() {
  const navigate = useNavigate()
  const handleGoBack = () => navigate(-1)
  return (
    <FullWideLayout header={<Header />}>
      <PlansContainerRedesign onClickBack={handleGoBack}/>
    </FullWideLayout>
  )
}
