import {Button} from "../Button";
import {reachGoal} from "../../utils/metrics";
import React from "react";
import {usePolotno} from "../../store/usePolotno";
import { ReactComponent as Question } from '../../components/plans/question.svg'
import { ReactComponent as Cross } from '../../components/plans/cards/cross-inactive.svg'
import { ReactComponent as DollarSymbol } from '../new-editor/dollar_symbol.svg'
import {useEditorProductPositionChangeState} from "../../store/useEditorProductPositionChangeState";
import {StoreType} from "polotno/model/store";
import styles from "./position-changed.module.css"
import {
    adjustBackground,
    getBackgroundGenerator,
    getPrompt,
    setCustomStoreEntityProps
} from "../new-editor/utils/image.utils";
import {hasCredits} from "../new-editor/utils/subscription.utils";
import {applyBackground, createProjectStatParams, getMainObjectBlob} from "../new-editor/utils/polotno.utils.js";
import {updateCredits} from "../../api/updateCredits";
import {MODAL_NAME as CREDITS_MODAL} from "../../ds/modal/BuyCreditsModal";
import {useCredits} from "../../store/useCredits";
import {useImageStore} from "../../store/useImageStore";
import {useFullPageLoading} from "../../contexts/FullPageLoadingContext";
import {useModalManager} from "../../store/useModalManager";

const adjustBackgroundEnabled = process.env.REACT_APP_ADJUST_BACKGROUND_ENDPOINT_ON === 'true';

type Props = {store: StoreType}

export const PositionChanged = ({store}: Props) => {
    const resetProductPosition = usePolotno(s => s.resetProductPosition)
    const hasChanged = useEditorProductPositionChangeState(s => s.hasChanged)
    const setHasChanged = useEditorProductPositionChangeState(s => s.setValue)
    const product = usePolotno(s => s.product)
    const { credits, setCredits } = useCredits()
    const imageResultStore = useImageStore()
    const { setLoading } = useFullPageLoading()
    const toggleModal = useModalManager((s) => s.toggleModal)

    const generateBackground = getBackgroundGenerator({store, imageResultStore})
    const generateAndApplyBackground = async (
        prompt: string,
        negativePrompt: string,
        name?: string,
        selectAiService: boolean = false
    ) => {
        if (hasCredits(credits)) {
            setLoading(true)
            setCustomStoreEntityProps(store, { currentPromptInStore: {prompt, negativePrompt}})
            setCustomStoreEntityProps(product, {currentPromptInStore: {prompt, negativePrompt}})
            const blob = await getMainObjectBlob(store, product)
            const oldMaskUrl = product.custom.maskUrl
            console.log(blob)

            const needTranslate = /[А-яёЁ]/.test(prompt);

            //TODO: if you're going to enable adjustBackground, add prompt translation support to its API, see needTranslate var above
            const res = (oldMaskUrl && adjustBackgroundEnabled)
                ? await adjustBackground(blob, prompt, oldMaskUrl, product.page.background, store.custom.cardSize)
                : await generateBackground(blob, prompt, negativePrompt, { selectAiService, translate: needTranslate })
            if(res.savedMaskUrl) setCustomStoreEntityProps(product, { maskUrl: res.savedMaskUrl})
            if (res.imageUrl) {
                setCustomStoreEntityProps(store, { imageUrl: res.imageUrl })
                applyBackground(store, res.imageUrl)
            } else console.error('Bad image url', res)
            if(res.credits){
                updateCredits(res.credits,setCredits)
                reachGoal("user_spent_credit", createProjectStatParams(store, product))
            }
            setLoading(false)
        } else if (credits !== null || credits == 0) {
            toggleModal(CREDITS_MODAL, true)
        }
    }

    const generate = async () => {
        const currentPrompt = getPrompt(product)
        if (
            store.activePage.background != 'transparent' &&
            currentPrompt.prompt
        ) {
            await generateAndApplyBackground(
                currentPrompt.prompt,
                currentPrompt.negativePrompt,
                '',
                false,
            )
        }
    }
    const resetPosition = () => {
        resetProductPosition()
        reachGoal('click_return_object')
        setHasChanged(false)
    }
    const adjust = async () => {
        await generate()
        reachGoal('click_adapt_background')
        setCustomStoreEntityProps(product, { forceShowPositionChanged: false })
        store && await store.waitLoading()
        setHasChanged(false)
    }
    return (
        <>
            {hasChanged && <div className="tool-panel_content_category_warning">
                <Cross className="tool-panel_cross" onClick={() => {
                    setHasChanged(false)
                    reachGoal('click_close_adapt_background')
                }}/>
                <div className="tool-panel_content_category_warning_label">
                    <div className="tool-panel_content_category_warning_text">
                        <h3 className="bodyM colorAccentPinkDark">Расположение объекта изменилось</h3>
                    </div>
                    <div className="tool-panel_content_category_tooltip">
                        <Question />
                        <span className="tool-panel_content_category_tooltip_text">
                      AI фон генерируется под конкретное расположение объекта, чтобы учесть его
                      размеры, создать правильные тени и отражения. Если вы изменили положение
                      объекта, нужно либо создать новый фон, либо вернуть объект на прежнее место.
                    </span>
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        size="m"
                        variation="primary"
                        onClick={adjust}
                    >
                        <div className={styles.buttonLabel}>
                            Подстроить фон
                            <div className={styles.priceLabel}>
                                <DollarSymbol style={{ marginTop: '-2px' }} />1
                            </div>
                        </div>
                    </Button>
                    <Button
                        className="return_object_button"
                        size="m"
                        variation="secondary"
                        onClick={resetPosition}
                    >
                        Вернуть объект
                    </Button>
                </div>
            </div>
            }
        </>
    )
}