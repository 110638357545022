import styles from './count.module.css'
import { useModalManager } from '../../store/useModalManager'
import { useCredits } from '../../store/useCredits'
import { useNavigate } from 'react-router-dom'
import { R_PLANS } from '../../router-constants'
import { useTranslation } from 'react-i18next'
export let countCredits = 0

const Count = () => {
  const credits = useCredits((s) => s.credits)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const toggleModal = useModalManager((s) => s.toggleModal)
  // const setScreen = useScreenManager((s) => s.setActiveScreen)
  const openCreditOutPopup = (): void => {
    navigate(R_PLANS)
  }

  return (
    <div onClick={openCreditOutPopup} className={styles.count}>
      <img
        src="/MonetizationOnRounded.svg"
        style={{ width: '32px', height: '32px' }}
        alt={t('pages.header.generationsCapitalLetter')}
      />
      <span className={`bodyM ${styles.countValue}`}>
        {credits && (credits == -1 ? t('pages.header.unlimited') : `${t('pages.header.generation', { count: credits })}`)}
        {credits == 0 && ` ${t('pages.header.ofGenerations')}`}
      </span>
    </div>
  )
}

export default Count
