import {StoreType} from "polotno/model/store";
import {addPageTemplate, getMainObject, getPageObject} from "./editor.utils";
import {setObjectBeforeDisplacement} from "./image.utils";

function splitText(numberOfParts: number, text: string, indexMainHeadersElement: number) {
    let splitString = []
    let arrayString = Array(numberOfParts).fill('')
    if (numberOfParts == 1) {
        splitString = [text]
    } else {
        const lengthHeadingPart = text.length / numberOfParts
        splitString = text.split(' ')

        if (splitString.length == 1) {
            arrayString[indexMainHeadersElement] = splitString[0]
            splitString = arrayString
        }

        if (splitString.length < numberOfParts && splitString.length != 1)
            splitString.push(...Array(numberOfParts - splitString.length).fill(''))

        if (splitString.length > numberOfParts) {
            let arrayStringIndex = 0

            arrayString[arrayStringIndex] = splitString.shift()

            for (let element of splitString) {
                if (arrayStringIndex + 1 < numberOfParts) {
                    if ((arrayString[arrayStringIndex] + ' ' + element).length <= lengthHeadingPart) {
                        arrayString[arrayStringIndex] += ' ' + element
                    } else {
                        arrayStringIndex++
                        arrayString[arrayStringIndex] = element
                    }
                } else {
                    arrayString[arrayStringIndex] += ' ' + element
                }
            }
            splitString = arrayString
        }
    }
    return splitString
}

export function replaceTitleInfographicsTemplate(element: any, heading: string, store: StoreType) {
    const splitString = splitText(
        element.numberOfHeaderParts,
        heading,
        element.indexMainHeadersElement
    )

    for (let i = 0; i < splitString.length; i++) {
        store.getElementById(`main_text_replace_${i}_${store.activePage.id}`)?.set({ text: splitString[i] })
    }
}
export async function addTemplate(element: any, infographicsData: any, store: StoreType, allPages = true) {
    let template = element.jsonRectangleTemplate
    if (store.custom.cardSize === '1_1' && element.jsonSquareTemplate) {
        template = element.jsonSquareTemplate
    }

    await store.history.transaction(async () => {
        const jsonTemplate = JSON.parse(template)

        if(allPages){
            for (const page of store.pages){
                await addPageTemplate(store, page, jsonTemplate, {
                    infographicsData,
                    replaceTitleInfographicsTemplate,
                    element,
                    replaceAboutTextInfographicsTemplate,
                })
            }
        } else {
            await addPageTemplate(store, store.activePage, jsonTemplate, {
                infographicsData,
                replaceTitleInfographicsTemplate,
                element,
                replaceAboutTextInfographicsTemplate,
            })
        }

    });

}

export function replaceAboutTextInfographicsTemplate(element: any, features: any, store: StoreType, infographicsData: any) {
    let splitString = []
    let aboutTextFieldName = ['first_about_text', 'second_about_text', 'third_about_text']
    if (element.numberOfAboutParts) {
        for (let j = 0; j < features.length; j++) {
            splitString = splitText(
                element.numberOfAboutParts,
                features[j],
                element.indexMainOfAboutElement
            )

            for (let i = 0; i < splitString.length; i++) {
                store.getElementById(`${aboutTextFieldName[j]}_${i}_${store.activePage.id}`)?.set({ text: splitString[i] })
            }
        }
    } else {
        for (let i = 0; i < aboutTextFieldName.length; i++) {
            store.getElementById(`${aboutTextFieldName[i]}_${store.activePage.id}`)?.set({ text: infographicsData.features[i] })
        }
    }
}
export const updateInfographicsAndCardSize = (store: StoreType) => {
    for(const page of store.pages) {
        if (store.custom.cardSize === '1_1') {
            page.setSize({width: 1200, height: 1200, useMagic: false})
            page.children.forEach((element: any) => {
                if (element.x == 0 && element.width == 900) {
                    element.set({width: element.width + 300})
                } else {
                    element.set({x: element.x + 150})
                }
            })
        }

        if (store.custom.cardSize === '3_4') {
            page.setSize({width: 900, height: 1200, useMagic: false})
            page.children.forEach((element: any) => {
                if (element.x == 0 && element.width == 1200) {
                    element.set({width: element.width - 300})
                } else {
                    element.set({x: element.x - 150})
                }
            })
        }
        setObjectBeforeDisplacement(getPageObject(store,page))
    }
}