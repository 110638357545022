import { FULL_FEATURES } from '../components/plans/plans-features' 
import { backendUrl } from '../config/app'
import {DEMO_PLAN_ID, Subscription} from '../store/useSubscriptions'
import { axiosInstance } from './axiosConfig'

export const subscriptionPlansTranslateKey = {
  standard: {
    keyInPlanFeatures: 'base',
    keyTitle: 'beginner'
  }, 
  premium: {
    keyInPlanFeatures: 'premium',
    keyTitle: 'seller'
  }, 
  business: {
    keyInPlanFeatures: 'business',
    keyTitle: 'business'
  },
  demo: {
    keyInPlanFeatures: 'demo',
    keyTitle: 'demo'
  }
}

export const fetchActiveSubscriptions = async () => {
  const response = await axiosInstance.get(`${backendUrl()}api/ngb/subscriptions`)

  const sbs = await response.data as Subscription[]

  getFeaturesListWithTranslate(sbs)

  return sbs
}

const getFeaturesListWithTranslate = (sbs: any) => {
  for(let element of sbs){
      if(element?.planDetails){
      try {
        if(element.planDetails.id === DEMO_PLAN_ID){
          element.planDetails.title = subscriptionPlansTranslateKey['demo'].keyTitle
          element.planDetails.features_list = FULL_FEATURES['demo']
          return;
        }

        const featureType: keyof typeof subscriptionPlansTranslateKey = element.planDetails.tier_type
        const selectedPlan: keyof typeof FULL_FEATURES = subscriptionPlansTranslateKey[featureType]?.keyInPlanFeatures as keyof typeof FULL_FEATURES
      
        element.planDetails.features_list = FULL_FEATURES[selectedPlan]
        element.planDetails.title = subscriptionPlansTranslateKey[featureType]?.keyTitle
      } catch (error) {
        console.error('Error with features list: ', error)
      }
    }
  }
}
