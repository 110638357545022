import { PropsWithChildren, useEffect } from 'react'
import cx from 'clsx'
import { CardPlanRedesign } from './cards/CardPlanRedesign'
import s from './plan-page-redesign.module.css'
import { Button } from '../Button'
import { ReactComponent as IconArrowLeft } from './icon-arrow-left.svg'
import { ReactComponent as Save40 } from './save40.svg'
import { Case, Switch } from '../CondRender/CondRender'
import {REDESIGN_FEATURES} from './plans-features'
import { useFullPageLoading } from '../../contexts/FullPageLoadingContext'
import { initRegularPayment } from '../../api/initRegularPayment'
import {
  subscriptionTiers,
  SubscriptionTierType,
  SubscriptionType,
  useSubscriptions,
} from '../../store/useSubscriptions'
import { initSubscription } from '../../api/initSubscription'
import { reachGoal } from '../../utils/metrics'
import { applyDiscountForPrice } from './promo-code/utils'
import { usePromocode } from '../../store/usePromocode'
import { useTranslation } from 'react-i18next'
import { backendUrl } from '../../config/app'
import { isRuLocale } from '../new-editor/utils/localization.utils'
import { maximizeSupport } from '../../utils/internationalSupport'
import { getCurrencyFormat } from '../new-editor/utils/subscription.utils'
import {ClientType, getPlans} from "./plans";
import { usePlans } from '../../store/usePlans'
import { ANNUAL_DISCOUNT } from '../new-editor/constants/promocode'
import {getActive} from "../UserProfile/subscrption-management/SubscriptionHOC";
import CountdownTimer from '../new-editor/components/CountdownTimer'
import usePromocodeHook from '../../hooks/usePromocodeHook'
import {PlansToggle} from "../PlansToggle/PlansToggle";
import {ToggleElement} from "../PlansToggle/ToggleElement";
import {InputPromoCodeBlockRedesign} from "./promo-code/InputPromoCodeBlockRedesign";
import InvoiceTextBlock from '../new-editor/components/InvoiceTextBlock'
import ApiAccessBlock from '../new-editor/components/apiAccessBlock/ApiAccessBlock'

export type PlansContainerProps = {
  onClickBack: () => void
}

type Plan = 'yearly' | 'monthly' | 'shop'

export function PlansContainerRedesign(props: PlansContainerProps) {
  const { onClickBack } = props
  const isPromoCodeApplied = usePromocode((s) => s.isApplied)
  const { setLoading } = useFullPageLoading()
  const { t } = useTranslation()
  const resetPromoCode = usePromocode((s) => s.reset)
  const { isShowUpgardePlanElements, secondsForUpgradeTimer, isShowAfterRegistrationButton, secondsForAfterRegistrationTimer } = usePromocodeHook()

  const ClientTypeTips: Record<ClientType, string> = {
    standard: 'Для тех, кто хочет выйти на маркетплейсы и создать свои первые продающие карточки.',
    premium: 'Универсальный тариф для большинства селлеров. Полный доступ ко всем функциям.',
    business: 'Для магазинов с большим ассортиментом. Полный доступ и безлимитные генерации.',
  }

  const formatAnnualTotal = (price: number): string => {
    return `${getCurrencyFormat(price)}/год`
  }

  const discountInfo = usePromocode((s) => ({
    plandIds: s.planIds,
    discount: s.discount,
    code: s.code,
  }))

  const getAnnualDiscountInfo = () => {
    const discount = ((1-(1-ANNUAL_DISCOUNT)*(1-discountInfo.discount/100))*100)
    return +discount.toFixed(1)
  }

  const applyDiscount = (sum: number, planId: string): number => {
    if (!isPromoCodeApplied) {
      return sum
    }

    return applyDiscountForPrice(sum, planId, discountInfo.plandIds!, discountInfo.discount)
  }

  const hasActiveSubscription = useSubscriptions((s) => s.hasActiveSubscription)
  const areSubscriptionExist = useSubscriptions((s) => s.activeSubscriptions.length > 0)
  const currentPlanDetails = useSubscriptions((s) => getActive(s.activeSubscriptions)?.planDetails)

  const setPlanTab = usePlans((s) => s.setPlanTab)
  const planTab = usePlans((s) => s.planTab)

  useEffect(() => {
    reachGoal('open_plans')
    setPlanTab('yearly')
  }, [])

  // Reset promocode status after unmount
  useEffect(() => {
    return () => {
      resetPromoCode()
    }
  }, [])

  type ButtonAction = 'buy' | 'current' | 'upgrade' | 'contact_support'

  const getActionBasedOnLocale = () => {
    return isRuLocale() ? 'upgrade' : 'contact_support'
  }

  const getButtonAction = (
    nextTier: SubscriptionTierType,
    nextTierType: SubscriptionType
  ): ButtonAction => {
    if (!currentPlanDetails) {
      return 'buy'
    }

    const { tier_type: currentTier, type: currentTierType } = currentPlanDetails
    const currentTierIndex = subscriptionTiers.indexOf(currentTier)
    const nextTierIndex = subscriptionTiers.indexOf(nextTier)

    if (nextTierType === currentTierType) {
      if (currentTierIndex > nextTierIndex) {
        return 'contact_support'
      } else if (currentTierIndex < nextTierIndex) {
        return getActionBasedOnLocale()
      } else {
        return 'current'
      }
    } else if (currentTierType === 'MONTHLY' && nextTierType === 'ANNUAL') {
      if (nextTierIndex >= currentTierIndex) {
        return getActionBasedOnLocale()
      } else {
        return 'contact_support'
      }
    } else if (currentTierType === 'ANNUAL' && nextTierType === 'MONTHLY') {
      return 'contact_support'
    }

    return 'contact_support'
  }

  const handleSubscriptionClick = async (
    planId: string,
    tier: SubscriptionTierType,
    type: SubscriptionType,
    stripePriceId?: string
  ) => {
    reachGoal('click_buy_credits')
    const buttonAction = getButtonAction(tier, type)

    if (buttonAction === 'buy' || buttonAction === 'upgrade') {
      setLoading(true)
      if (!isRuLocale()) {
        const res = await fetch(`${backendUrl()}api/subscription`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ priceId: stripePriceId, planId }),
        })

        const data = res.ok && (await res.json())
        window.location.href = data.redirectUrl;
        setLoading(false)
      } else {
        try {
          const url = await initSubscription(planId, discountInfo.code ?? undefined)
          window.location.href = url;
        } catch (e) {
          console.error(e)
          alert(t('pages.plans.paymentInitiationFailed'))
        } finally {
          setLoading(false)
        }
      }
    } else {
      if (isRuLocale()) {
        const contactSupportUrl = `https://t.me/mpcardai_support?text=${encodeURIComponent(
          t('pages.plans.changeSubscriptionPlan') + ':\n'
        )}`
        window.open(contactSupportUrl, '_blank')
      } else {
        maximizeSupport()
      }
    }
  }

  const handleRegularPayment = async (option: string) => {
    setLoading(true)
    reachGoal('click_buy_credits')
    try {
      const url = await initRegularPayment(option)
      location.href = url
    } catch (e) {
      console.error(e)
      alert(t('pages.plans.paymentInitiationFailed'))
    } finally {
      setLoading(false)
    }
  }

  const withAnalytics =
    (
      event: string,
      planId: string,
      tier: SubscriptionTierType,
      type: SubscriptionType,
      stripePriceId?: string
    ) =>
    () => {
      reachGoal(event)
      handleSubscriptionClick(planId, tier, type, stripePriceId)
    }

  const withAnalyticsPacket = (event: string, opt: string) => () => {
    reachGoal(event)
    handleRegularPayment(opt)
  }

  const checkDiscountAllowed = (planId?: string): boolean => {
    if (!planId || !isPromoCodeApplied || !discountInfo) {
      return false
    }

    if (discountInfo.plandIds && discountInfo.plandIds.length > 0) {
      return discountInfo.plandIds.includes(planId)
    }

    return true
  }

  const getButtonText = (tier: SubscriptionTierType, type: SubscriptionType) => {
    const labels = {
      buy: t('pages.plans.buy'),
      current: t('pages.plans.currentPlan'),
      upgrade: t('pages.plans.upgradePlan'),
      contact_support: t('pages.plans.changePlan'),
    }

    const buttonAction = getButtonAction(tier, type)

    return labels[buttonAction]
  }

  const isButtonDisabled = (tier: SubscriptionTierType, type: SubscriptionType) => {
    return getButtonAction(tier, type) === 'current'
  }

  const gerAdditionalGenerationsTitle = (count: string) => {
    return count + ' ' + t('pages.header.ofGenerations')
  }

  const isButtonActionUpgrade = (tier: SubscriptionTierType, type: SubscriptionType) => {
    return getButtonAction(tier, type) === 'upgrade'
  }

  const getPlanWithDiscountMessageKey = () => {
    return hasActiveSubscription ? 'pages.plans.renewPlanWithDiscountMessage' : 'pages.plans.drawPlanWithDiscountMessage'
  }

  const plans = getPlans()

  return (
    <div className={s.Page}>
      <div>
        <div className={s.TopActions}>
          <Button
            size="s"
            variation="secondary"
            grow={false}
            onClick={onClickBack}
            className={s.BackButtonContainer}
          >
            <div className={s.BackButton}>
              <div>
                <IconArrowLeft />
              </div>
              <div>{t('pages.profile.returnToEditor')}</div>
            </div>
          </Button>
        </div>
        <div className={s.HeaderContainer}>
          <h1 className={cx(s.Header,'titleL')}>{t('pages.plans.choosePlan')}</h1>
          <div className={s.SubHeader}>
            <span className={s.hg}>Экономьте -40%</span> с годовой подпиской
          </div>
          {isRuLocale() && isShowUpgardePlanElements() && (
            <>
              <div className={s.timerBlock}>
                <CountdownTimer initialSeconds={secondsForUpgradeTimer()}/>
              </div>
              <p className={s.bottomTimerText}>{t(getPlanWithDiscountMessageKey())}</p>
            </>
          )}
          {isRuLocale() && isShowAfterRegistrationButton() && (
            <>
              <div className={s.timerBlock}>
                <CountdownTimer initialSeconds={secondsForAfterRegistrationTimer()}/>
              </div>
              <p className={s.bottomTimerText}>{t(getPlanWithDiscountMessageKey())}</p>
            </>
          )}
          {isRuLocale() && <InputPromoCodeBlockRedesign />}

        </div>

        <div className={s.TabsContainer}>
          <PlansToggle>
            <ToggleElement onClick={() => setPlanTab('yearly')} isActive={planTab == 'yearly'} size="m">
              {t('pages.plans.yearly')}
            </ToggleElement>
            <ToggleElement onClick={() => setPlanTab('monthly')} isActive={planTab == 'monthly'} size="m">
              {t('pages.plans.monthly')}
            </ToggleElement>
          </PlansToggle>
          <Save40 className={s.save40}/>
        </div>

        <div className={s.PlanContainer}>
          <Switch>
            <Case condition={planTab == 'yearly'}>
              {plans.yearlyPlans.map((plan) => {
                const discountedPrice = applyDiscount(plan.price, plan.planId!) / 12
                const price = isRuLocale()? Math.floor(discountedPrice) : Math.floor(discountedPrice * 100) / 100
                const priceDescription = formatAnnualTotal(applyDiscount(plan.price, plan.planId!))
                const clientTypeTip = ClientTypeTips[plan.clientTypeTipKey!]
                const buttonText = getButtonText(plan.tier!, plan.type!)
                const disableButton = isButtonDisabled(plan.tier!, plan.type!)
                const featuresList = REDESIGN_FEATURES[plan.featuresListKey!]

                const onButtonClick = withAnalytics(
                  plan.analyticsEvent!,
                  plan.planId!,
                  plan.tier!,
                  plan.type!,
                  plan.stripePriceId
                )

                return (
                  <CardPlanRedesign
                    key={plan.planId}
                    planId={plan.planId}
                    title={t(plan.titleKey)}
                    subTitle={plan.subTitleKey ? t(plan.subTitleKey) : undefined}
                    clientTypeTip={clientTypeTip}
                    price={price}
                    originalPrice={plan.originalPrice}
                    priceDescription={priceDescription}
                    totalBeforeDiscount={plan.totalBeforeDiscount}
                    pricePostfix={"месяц"}
                    highlight={plan.highlight}
                    featuresList={featuresList}
                    onButtonClick={onButtonClick}
                    buttonText={buttonText}
                    disableButton={disableButton}
                    discountAmount={getAnnualDiscountInfo()}
                    checkDiscountAllowed={checkDiscountAllowed}
                    btnTooltipText={t('pages.plans.payDifferenceMessage')}
                    useButtonWithTooltip={isButtonActionUpgrade(plan.tier!, plan.type!)}
                  />
                )
              })}
            </Case>

            <Case condition={planTab == 'monthly'}>
              <ComponentWithAnalytics event="visit_monthly_plans">
                {plans.monthlyPlans.map((plan) => {
                  const price = applyDiscount(plan.price, plan.planId!)
                  const clientTypeTip = ClientTypeTips[plan.clientTypeTipKey!]
                  const buttonText = getButtonText(plan.tier!, plan.type!)
                  const disableButton = isButtonDisabled(plan.tier!, plan.type!)
                  const featuresList = REDESIGN_FEATURES[plan.featuresListKey!]

                  const onButtonClick = withAnalytics(
                    plan.analyticsEvent!,
                    plan.planId!,
                    plan.tier!,
                    plan.type!,
                    plan.stripePriceId
                  )

                  return (
                    <CardPlanRedesign
                      key={plan.planId}
                      planId={plan.planId}
                      title={t(plan.titleKey)}
                      subTitle={plan.subTitleKey ? t(plan.subTitleKey) : undefined}
                      clientTypeTip={clientTypeTip}
                      price={price}
                      originalPrice={plan.originalPrice}
                      pricePostfix={t('pages.plans.month')}
                      highlight={plan.highlight}
                      featuresList={featuresList}
                      onButtonClick={onButtonClick}
                      buttonText={buttonText}
                      disableButton={disableButton}
                      discountAmount={discountInfo.discount}
                      checkDiscountAllowed={checkDiscountAllowed}
                      btnTooltipText={t('pages.plans.payDifferenceMessage')}
                      useButtonWithTooltip={isButtonActionUpgrade(plan.tier!, plan.type!)}
                    />
                  )
                })}
              </ComponentWithAnalytics>
            </Case>
          </Switch>
        </div>
        {isRuLocale() && (
          <InvoiceTextBlock />
        )}
        {isRuLocale() && (
          <ApiAccessBlock />
        )}
      </div>
    </div>
  )
}

const ComponentWithAnalytics = (p: PropsWithChildren<{ event: string }>) => {
  useEffect(() => {
    if (p.event) {
      reachGoal(p.event)
    }
  }, [p.event])
  return p.children as any
}
