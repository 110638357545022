import { useFullPageLoading } from "../contexts/FullPageLoadingContext"
import { useClosePanelInMobile } from './useClosePanelInMobile'
import { StoreType } from "polotno/model/store";
import { usePolotno } from "../store/usePolotno";
import { addTemplate } from "../components/new-editor/utils/infographics.utils";
import { getMainObject } from "../components/new-editor/utils/editor.utils";
import { useAddInfographics } from "../store/useAddInfographics";
import { setCustomStoreEntityProps } from "../components/new-editor/utils/image.utils";

const isDeleteTemplate = (template: {key: number}): boolean => template.key === 0;
const displaysBackgroundsBadge = (template: {key: number}, store: StoreType) =>
    !isDeleteTemplate(template) && !store.custom?.infographicsApplied

export const useInfographics = () => {
    const {setLoading} = useFullPageLoading()
    const closePanelInMobile = useClosePanelInMobile()
    const store = usePolotno(s => s.store)
    const infographics = usePolotno(s => s.infographics)
    const setProduct = usePolotno(s => s.setProduct)
    const setShowGenerate = useAddInfographics(s => s.setShowGenerate)
    const setActiveTemplate = useAddInfographics(s => s.setActiveTemplate)

    const setActiveTemplateKey = (key: number) => {
        setCustomStoreEntityProps(store, { activeInfographicsTemplate: key })
    }

    const applyInfographics = async (template: {key: number}, allPages = true) => {
        setLoading(true)
        try {
            closePanelInMobile()
            setActiveTemplate(template)
            setActiveTemplateKey(template.key)
            displaysBackgroundsBadge(template, store) && setShowGenerate(true)
            await addTemplate(template, infographics, store, allPages)
            setProduct(getMainObject(store))
            setLoading(false)
        } catch (e){
            setLoading(false)
        }
    }

    return { applyInfographics }
}