import { getProjectId } from '../../../logger/logEvent'
import { getRandomAIServiceChoice } from '../../../utils/ai-gen'
import { generateAiBgThumb } from '../../../api/generateAiThumb'
import { generateAiBackground as generateAiBackgroundAPI } from '../../../api/generateAiBackground'
import { StoreType } from 'polotno/model/store'
import {
  setTranslations,
  unstable_setTextVerticalResizeEnabled,
  unstable_setTextOverflow,
  setAPI,
} from 'polotno/config'
import { isRuLocale } from './localization.utils'
import { polotnoRussianTranslations } from '../../../utils/polotnoRussianTranslation'
import {centerObjectInRect, positionChanged} from './editor.utils'
import { backendUrl } from '../../../config/app'
import {cloneDeep} from "lodash";
import axios from "axios";
import { __ } from "polotno/utils/validate-key";
import { getKey } from "polotno/utils/validate-key";


export const createImage = async (imageUrl: string): Promise<HTMLImageElement> => {
  const response = await fetch(imageUrl)
  const blob = await response.blob()
  const img = new Image()
  img.src = URL.createObjectURL(blob)

  return img
}

export const adjustBackground = async (blob: any, prompt: string, oldMaskUrl: string, background: string, cardSize: any) => {
  const formData = new FormData()
  const pid = getProjectId()
  formData.append('file', blob, 'image.webp')
  formData.append('prompt', prompt)
  formData.append('oldMaskImgUrl', oldMaskUrl)
  formData.append('oldImageUrl', background)
  formData.append('cardSize', cardSize)

  const { data } = await axios.post(
      `${backendUrl()}api/adjust-background?pid=${pid}`,
      formData
  )

  return data
}

export const getBackgroundGenerator =
  ({ store, imageResultStore }: any) =>
  async (
    blob: any,
    prompt: string,
    negativePrompt: string,
    options: { isSuggested?: boolean; translate?: boolean; selectAiService?: boolean } = {
      isSuggested: false,
      translate: false,
      selectAiService: false,
    }
  ) => {
    await store.waitLoading()
    const pid = getProjectId()
    const { isSuggested, selectAiService } = options
    const formData = new FormData()
    formData.append('file', blob, 'image.webp')
    formData.append('prompt', prompt)
    formData.append('negativePrompt', negativePrompt)
    formData.append('cardSize', store.custom.cardSize)
    if (options.translate) {
      formData.append('translate', options.translate.toString())
    }
    let srv = getRandomAIServiceChoice() // cobalt | photoroom

    let response = null

    if (isSuggested) {
      const res = await generateAiBgThumb(formData, pid)
      response = { imageUrl: res.thumbUrl, meta: res, srv: 'cobalt' }
    } else {
      const res = await generateAiBackgroundAPI(formData, pid)
      response = res.data
    }

    if (!response || !response.imageUrl) {
      console.error('An error occurred:', response)
    }

    imageResultStore.storeUrl(response.imageUrl, 'cobalt')
    if (selectAiService) {
      imageResultStore.setCurrentByImageUrl(response.imageUrl)
    }
    return { ...response, srv }
  }
type PlacementMeta = {
  jsonTemplate: Record<string, any>
  setPositionChangedInFunction: (f: boolean) => void
}

export function placeObjectInTemplate(
  store: any,
  page: any,
  currentObject: any,
  templateElement: any,
  meta: PlacementMeta
) {
  const boundingRect = {
    x: templateElement.x,
    y: templateElement.y,
    width: templateElement.width,
    height: templateElement.height,
  }
  const { x, y, width, height } = centerObjectInRect(currentObject, boundingRect)
  templateElement.x = x
  templateElement.y = y
  templateElement.width = width
  templateElement.height = height

  templateElement.src = currentObject.src
  templateElement.custom = cloneDeep(currentObject.custom)

  if (
    page.background.includes('/') &&
    !page.background.includes('http') &&
    !page.background.includes('infographics') &&
      positionChanged(currentObject.custom.objectPositionBeforeDisplacement, templateElement)
  ) {
    templateElement.custom.forceShowPositionChanged = true
  }

  const newObject = page.addElement({
    ...templateElement,
    id: `object_${page.id}`,
    name: 'object',
    removable: false,
  })
  if (!page.background.includes('/')) {
    setObjectBeforeDisplacement(newObject)
  }
  store.deleteElements([templateElement.id])
}

export function setObjectBeforeDisplacement(mainObject: any): void {
  setCustomStoreEntityProps(mainObject, {
    objectPositionBeforeDisplacement: {
      x: mainObject.x,
      y: mainObject.y,
      width: mainObject.width,
      height: mainObject.height,
      rotation: mainObject.rotation,
    },
  })
}

export function setStoreProps(
  store: any,
  { backgroundType, backgroundKey, backgroundPrompt, infographicsTemplate, cardSize }: any
) {
  store.setSize(store.custom?.cardSize === '1_1' ? 1200 : 900, 1200)
  if (cardSize) {
    setCustomStoreEntityProps(store, { cardSize: cardSize })
    store.activePage.setSize({ width: store.custom.cardSize === '1_1' ? 1200 : 900, height: 1200 })
  }

  if (backgroundType) {
    setCustomStoreEntityProps(store, {
      activeBackgroundType: backgroundType,
    })
  }

  if (backgroundKey) {
    setCustomStoreEntityProps(store, {
      activeBackgroundKey: backgroundKey,
    })
  }

  if (backgroundPrompt) {
    setCustomStoreEntityProps(store, {
      currentPromptInStore: {
        prompt: backgroundPrompt,
      },
    })
  }

  if (infographicsTemplate != null) {
    setCustomStoreEntityProps(store, {
      activeInfographicsTemplate: infographicsTemplate,
    })
  }
}

export function setCustomStoreEntityProps(entity: any, custom: Record<string, any>) {
  entity.set({ custom: { ...(entity.custom || {}), ...custom } })
}

export function setupEditor(store: StoreType) {
  unstable_setTextVerticalResizeEnabled(true)
  unstable_setTextOverflow('change-font-size')
  if (isRuLocale()) {
    setTranslations(polotnoRussianTranslations)
  }

  setAPI('nounProjectList', ({ query, page = 1 }: { query: string; page: number }) => {
    return `${backendUrl()}api/icon_search?query=${query}&page=${page}`
  })
  setAPI('unsplashList', ({ query, page = 1 }: { query: string; page: number }) => {
    return `${backendUrl()}api/background_search?query=${query}&page=${page}`
  })

  const DOMAIN = 'https://api.polotno.com.ru'

  __(async (url:string, options:any) => {
    return fetch(url.replace("https://api.polotno.com", DOMAIN),options);
  });

  const replaceAll = (string:string, old:string, newStr:string) => {
    return string.replace(new RegExp(old, "g"), newStr);
  };

  setAPI("googleFontsList", () => {
    return `${DOMAIN}/api/get-google-fonts?KEY=${getKey()}`;
  });

  setAPI("googleFontImage", (fontFamily:any) => {
    return `${DOMAIN}/google-fonts-previews/black/${replaceAll(fontFamily," ","-")}.png`;
  });

  setAPI("nounProjectDownload", (id:string) => {
    return `${DOMAIN}/api/download-nounproject?id=${id}&KEY=${getKey()}`;
  });

  setAPI("aiText", (id:string) => {
    return `${DOMAIN}/api/ai/text?KEY=${getKey()}`;
  });

  store.addPage()
}

export const getPrompt = (product: any) => ({
  prompt: product.custom.currentPromptInStore?.prompt,
  negativePrompt: product.custom.currentPromptInStore?.negativePrompt,
})