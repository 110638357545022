import { useAuthContext } from '../contexts/AuthProvider'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { R_SIGN_UP_PAGE } from '../router-constants'

import { useCredits } from '../store/useCredits'
import { checkLoginStatus } from '../api/checkLoginStatus'
import { fetchCredits } from '../api/fetchCredits'
import { sendUtmData } from '../logger/source_saver'
import { sendUserId } from '../utils/metrics'
import { useEffect, useState } from 'react'
import { usePromotion } from '../store/usePromotion'

export const usePreserveQueryParamsNavigate = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (path: string) => {
    navigate(path + search);
  };
};

export function AuthProtectedPage() {
  const { hasAuthenticated, setUserId, setAuthenticated, setHasCompletedRegistration, setMarketingConsent, setIsTimeToShowTheVideo } = useAuthContext()
  const [hasChecked, setCheckStatus] = useState(false)
  const setCredits = useCredits((s) => s.setCredits)
  const navigate = usePreserveQueryParamsNavigate();
  const { initializeIsSet } = usePromotion()

  useEffect(() => {
    ;(async () => {
      try {
        const result = await checkLoginStatus()
        if (result.status) {
          if (result.userId) {
            setUserId(result.userId)
            setAuthenticated(true)            

            sendUtmData()
            sendUserId(result.userId)
            initializeIsSet(result.userId)
          }

          const credits: number | null = await fetchCredits()
          if (credits !== null) {
            setCredits(credits)
          }
  
          if (result.hasCompletedRegistration != null) {
            setHasCompletedRegistration(result.hasCompletedRegistration)
          }

          setMarketingConsent(result.marketingConsent)
          setIsTimeToShowTheVideo(true)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setCheckStatus(true)
      }
    })()
  }, [])
  if (!hasAuthenticated && hasChecked) {
    navigate(R_SIGN_UP_PAGE)
  }

  return <Outlet />
}
