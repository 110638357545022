import { backendUrl } from '../config/app'
import { axiosInstance } from './axiosConfig'

export const fetchCredits = async () => {
  const response = await axiosInstance.get(`${backendUrl()}api/check-credits`)
  if (response?.data.hasCredits) {
    return response?.data?.credits || null
  } else {
    return 0
  }
}
