import {ValueType, ValueType as BgMapType} from "../../../store/useSelectedBackgrounds";
import {Card, LOADING_IMG} from "../aicard/aiCardsContext";
import {getProjectId} from "../../../logger/logEvent";
import {upscaleThumb as upscaleThumbAPI} from "../../../api/upscaleImage";
import {StoreType} from "polotno/model/store";
import { getObject } from "./polotno.utils.js";
import { setObjectBeforeDisplacement } from "./image.utils";
import { setCustomStoreEntityProps } from "./image.utils";

export const getPreviouslySelected = (thumb: string | undefined, selectedBackgrounds: Record<string, ValueType>): BgMapType | null => {
    return thumb ? selectedBackgrounds[thumb] : null
}
export const isActiveCard = (thumb: string | undefined, selectedBackgrounds: Record<string, ValueType>) =>
    !!getPreviouslySelected(thumb, selectedBackgrounds)

export const upscaleAndApplyBackground = async (store: StoreType, card: Card, fn?: (bg: string) => void) => {
    if (card.meta) {
        const pid = getProjectId()
        const result = await upscaleThumbAPI({ ...card.meta }, pid)

        if (result.url) {
            const object = getObject(store.activePage, store);
            setObjectBeforeDisplacement(object)
            setCustomStoreEntityProps(object, { forceShowPositionChanged: false })

            store.activePage.set({ background: result.url })
            if (fn) {
                fn(result.url)
            }
            return result
        } else {
            console.error('Url is missing in response', result)
            return null
        }
    }
}

export const getImageWidthAndHeight = async (url: string): Promise<Array<number>> => {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = url
        img.onload = function() {
            resolve([img.width, img.height])
        }
        img.onerror = ev => reject(ev)
    })
}

export const getNewCard = (dataUrl: string, meta: any, card: Card, srv: string) => ({
    ...card, dataUrl, loading: false, updating: false, meta: { ...meta, prompt: card.prompt, srv },
})

export const cardsReady = (cards: Card[] | undefined) => {
    return cards && Array.isArray(cards) && cards.length > 0 && cards[0].dataUrl !== LOADING_IMG
}