import { backendUrl } from '../config/app'
import { Subscription } from '../store/useSubscriptions'
import { axiosInstance } from './axiosConfig'

export const deleteSubscription = async (subscriptionId: string) => {
  const response = await axiosInstance.delete(
    `${backendUrl()}api/ngb/payments/delete-subscription/${subscriptionId}`
  )

  const sbs = response.data as Subscription[]

  return sbs
}
