import { createBrowserRouter } from 'react-router-dom'
import {
  R_START_PAGE,
  R_SIGN_IN_PAGE,
  R_FORGOT_PASSWORD_PAGE,
  R_SIGN_UP_PAGE,
  R_THANKS_SIGN_UP,
  R_PLANS,
  R_USER_PROFILE,
  R_PROJECT_PAGE, R_ADD_DEMO_PLAN,
} from './router-constants'

// import { LoginPage, EditorPage, RegisterPage, FileUploadPage } from './pages'
import {
  AuthProtectedPage,
  SignInPage,
  StartPage,
  ForgotPasswordPage,
  SignUpPage,
  ThanksForSignUpPage,
  PlansPage,
  UserProfilePage,
  ProjectWorkspacePage,
  DemoPlanPage,
} from './pages/index'
import { CompletedRegistrationProtectedPage } from './pages/CompletedRegistrationProtectedPage'

export const router = createBrowserRouter([
  {
    path: R_SIGN_IN_PAGE,
    element: <SignInPage />,
  },
  {
    path: R_SIGN_UP_PAGE,
    element: <SignUpPage />,
  },
  {
    path: R_FORGOT_PASSWORD_PAGE,
    element: <ForgotPasswordPage />,
  },
  {
    path: R_THANKS_SIGN_UP,
    element: <ThanksForSignUpPage />,
  },
  {
    path: R_PLANS,
    element: <PlansPage />,
  },
  {
    element: <AuthProtectedPage />,
    children: [
      {
        element: <CompletedRegistrationProtectedPage />,
        children: [
          {
            path: R_START_PAGE,
            element: <StartPage />,
          },
          {
            path: R_PROJECT_PAGE,
            element: <ProjectWorkspacePage />,
          }
        ]
      },
      {
        path: R_USER_PROFILE,
        element: <UserProfilePage />,
      },
      {
        path: R_ADD_DEMO_PLAN,
        element: <DemoPlanPage/>,
      }
    ],
  },
])
