import s from './modal.module.css'
import {SharedProps} from '../../components/ModalDialog/ModalDialog'
import boxStarImage from './box-star.png'
import cx from 'clsx'
import {Button} from '../../components/Button'
import {useNavigate} from 'react-router-dom'
import {R_START_PAGE} from '../../router-constants'

export const DEMO_PLAN_MODAL = 'demoPlan'

export type DemoPlanModalProps = {
    isSuccess: boolean
}

export function DemoPlanModal(props: SharedProps & DemoPlanModalProps) {
    const {isSuccess} = props
    const navigate = useNavigate()
    const {close} = props

    return (
        <div>
            <img className={s.imgPopup} src={boxStarImage}/>

            {isSuccess && (
                <>
                    <h1 className={cx(s.mainTextPopup, 'titleM')}>Готово!</h1>

                    <div className={cx(s.creditsTextPopup, 'bodyM')}>Ваш демо-доступ активирован</div>

                    <div className={cx(s.minorTextPopup, 'paragraphM')}>
                        У вас есть 1 день, чтобы опробовать все возможности MPCard. Если у вас есть вопросы или
                        предложения, вы всегда можете связаться с нами через Telegram по кнопке в углу.
                    </div>
                </>
            )}
            {!isSuccess && (
                <>
                    <h1 className={cx(s.mainTextPopup, 'titleM')}>Ошибка!</h1>

                    <div className={cx(s.minorTextPopup, 'paragraphM')}>
                        К сожалению, для этого аккаунта недоступен демо-доступ.<br/> Возможно, вы уже имели демо-доступ ранее или у вас есть активная подписка.
                    </div>
                </>
            )}


            <div className={s.popupButtons}>
                <Button
                    size="m"
                    grow={false}
                    onClick={() => {
                        navigate(R_START_PAGE)
                        close()
                    }}
                >
                    Вернуться в редактор
                </Button>
            </div>
        </div>
    )
}
