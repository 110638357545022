import {ProjectObject} from "../../domain/types/Project";
import {LoadingModal} from "../LoadingModal/LoadingModal";
import {PolotnoContainer, SidePanelWrap, WorkspaceWrap} from "polotno";
import {Toolbar} from "polotno/toolbar/toolbar";
import {Workspace} from "polotno/canvas/workspace";
import {ZoomButtons} from "polotno/toolbar/zoom-buttons";
import React, {Ref, useCallback, useEffect, useRef, useState} from "react";
import {CustomSidePanel} from "./side-panel";
import {DEFAULT_STORE_PROPS, usePolotno} from "../../store/usePolotno";
import {addProjectObject, getObject, saveProject} from "./utils/polotno.utils.js";
import { reachGoal } from "../../utils/metrics";
import NewCardTool from "./sections/NewCardTool";
import debounce from 'lodash/debounce'
import {MultiAssetTool} from "./sections/MultiAsset/MultiAssetTool";
import {PagesTimeline} from "./components/PagesTimeline";
import {useSubscriptions} from "../../store/useSubscriptions";
import '@blueprintjs/core/lib/css/blueprint.css'
import './editor.css'
import {useProjectsSaving} from "../../store/useProjects";
import {AiBackgroundTool} from "./sections/AiBackgroundsTool";
import {Card, useCards} from "./aicard/aiCardsContext";
import {getSuggestedBackgrounds, onBackgroundsLoaded} from "./utils/backgrounds.utils";
import {cardsReady} from "./utils/card.utils";
import {getProjectId} from "../../logger/logEvent";
import {backendUrl} from "../../config/app";
import TextsTool from "./sections/TextsTool";
import {ElementsTool} from "./sections/Elements/ElementsTool";
import {UploadTool} from "./sections/UploadTool";
import {BackgroundsTool} from "./sections/Backgrounds/BackgroundsTool";
import {InfographicsTool} from "./sections/InfographicsTool";
import {ResizeTool} from "./sections/Resize/ResizeTool";
import { ReplaceObjectButton } from "./ReplaceObjectButton";
import { ReplaceObject } from "../ModalDialog/ReplaceObject";
import { ModalDialog } from "../ModalDialog/ModalDialog";
import { ReplaceObjectModalDialogComponent } from "../ModalDialog/ReplaceObjectModalDialogComponent";
import {DownloadButton} from "./DownloadButton";
import { useTranslation } from 'react-i18next'
import {setCustomStoreEntityProps, setStoreProps, setupEditor} from "./utils/image.utils";
import {isEnLocale, isRuLocale} from "./utils/localization.utils";
import {useResize} from "../../hooks/useResize";
import { useModalManager } from "../../store/useModalManager";
import { MODAL_NAME as OPEN_OVERLAY } from "../../ds/modal/OverlayModal";
import {getMainObject, getObjectPosition, positionChanged} from "./utils/editor.utils";
import {useEditorProductPositionChangeState} from "../../store/useEditorProductPositionChangeState";
import {StoreType} from "polotno/model/store";
import {isEmpty} from "lodash";

export type EditorProps = {
    backgroundRemoved: boolean
    project: ProjectObject
}

const sections: unknown[] = [NewCardTool,InfographicsTool, AiBackgroundTool, MultiAssetTool,
    BackgroundsTool, UploadTool, ElementsTool, TextsTool, ResizeTool];


const getInfographics = async (imageUrl: string) => {
    const pid = getProjectId()
    let result = await fetch(
        `${backendUrl()}api/infographics?imageUrl=${encodeURIComponent(imageUrl)}&pid=${pid}`
    )
    return result.json()
}

export interface EditorRef {
    onDownloadClick: () => void
}

const isNewProject = (project: ProjectObject) => !project.state.infographicData

const isAiBackground = (store: StoreType) =>
    store.activePage.background &&
    store.activePage.background.includes('/') &&
    !store.activePage.background.includes('http') &&
    !store.activePage.background.includes('infographics')

export const Editor = React.forwardRef(({project, backgroundRemoved}: EditorProps, ref: Ref<EditorRef>) => {
    const [isInit, setIsInit] = useState(false)
    const store = usePolotno(s => s.store)
    const setProduct = usePolotno(s => s.setProduct)
    const product = usePolotno(s => s.product)
    const setInfographics = usePolotno(s => s.setInfographics)
    const infographics = usePolotno( s => s.infographics)
    const hasPremiumSubscription = useSubscriptions(s => s.hasPremiumSubscription)
    const setProjectSaving = useProjectsSaving((s) => s.setProjectSaving)
    const isProjectSaving = useProjectsSaving((s) => s.isSaving)
    const { cards, setBackgrounds } = useCards()
    const setPositionChanged = useEditorProductPositionChangeState(s => s.setValue)
    const hasPositionChanged = useEditorProductPositionChangeState(s => s.hasChanged)
    const { t } = useTranslation()
    const {isScreenDesktop} = useResize()
    const toggleModal = useModalManager((s) => s.toggleModal)
    const productRef = useRef(getObjectPosition(product))

    const initNewProject = async (project: ProjectObject) => {
        store.activePage.setSize({width: store.width, height: store.height})
        try {
            await addProjectObject(store, project.state.sourceImage)
            setCustomStoreEntityProps(store, {loaded: true})
        } finally {
            const object = getObject(store.activePage, store)
            setProduct(object)
            setCustomStoreEntityProps(object, {objectPositionBeforeDisplacement: getObjectPosition(object)})
        }
        store.history.clear()
        await store.waitLoading()

        if(isEnLocale() && !isScreenDesktop){
            store.openSidePanel(AiBackgroundTool.name)
        }
    }

    const trackLocationUpdate = () => {
        //console.log('set positionChanged true')
        setPositionChanged(true)
    }

    const setCards = async (project: ProjectObject, set: (c: Card[]) => void) => {
        if(cardsReady(project.state.cards)) setBackgrounds(cards)
        else await getSuggestedBackgrounds(project.state.sourceImage, set)
    }

    const initProject = async () => {
        const object = getObject(store.activePage, store)
        setProduct(object)
        if(isAiBackground(store)) {
            if(!object.custom?.objectPositionBeforeDisplacement) {
                setCustomStoreEntityProps(object, {objectPositionBeforeDisplacement: getObjectPosition(object)})
            }
            if(!object.custom?.currentPromptInStore) {
                setCustomStoreEntityProps(object, {currentPromptInStore: store.custom?.currentPromptInStore})
            }
        }
    }

    const initStore = (project: ProjectObject) => {
        store.clear()
        setCustomStoreEntityProps(store, DEFAULT_STORE_PROPS)
        setupEditor(store)
        setStoreProps(store, project.state)
        setPositionChanged(false)
    }
    const init = async () => {
        if(isNewProject(project)) setIsInit(true)
        initStore(project);
        project.state.polotnoStore && store.loadJSON(project.state.polotnoStore)
        await store.waitLoading()
        if(isNewProject(project)) await initNewProject(project)
        else await initProject()
        await setCards(project, onBackgroundsLoaded(setBackgrounds))
        setInfographics(project.state.infographicData ?? await getInfographics(project.state.sourceImage))
        setIsInit(false)
        if(isEnLocale() && !isScreenDesktop && store.openedSidePanel) toggleModal(OPEN_OVERLAY, true)
    }
    const finalizeSave = useCallback(() => {
        setProjectSaving(false)
    }, [])
    useEffect(() => {
        init();
        reachGoal("project_loaded")
    }, [])

    useEffect(() => {
        const unsubscribe = store.on('change', () => {
            setProjectSaving(true)
            saveProject(project, store,{cards, infographics}, finalizeSave)
        })
        return () => unsubscribe()
    }, [cards, infographics])

    const handleStoreChange = () => {
        //console.log('store change')
        const product = getMainObject(store)
        const previousPosition = product?.custom?.objectPositionBeforeDisplacement || store.custom?.objectPositionBeforeDisplacement || {}
        //console.log('previous position',previousPosition)
        if (product && isAiBackground(store) && !isEmpty(previousPosition)) {
            //console.log('isAiBackground + !isEmpty', getObjectPosition(product), previousPosition, productRef.current)
            if (positionChanged(product, previousPosition) && positionChanged(product, productRef.current)) {
                //console.log('position changed',previousPosition, productRef, productRef.current)
                trackLocationUpdate()
            }

            productRef.current = getObjectPosition(product)
        }
    }

    useEffect(() => {
        const debouncedHandleStoreChange = debounce(handleStoreChange, 500);
        const unsubscribe = store.on('change', debouncedHandleStoreChange)

        return () => {
            debouncedHandleStoreChange.flush()
            unsubscribe()
        }
    }, [product, store])

    useEffect(() => {
        if(store.activePage?.id) {
            const object = getMainObject(store)
            setProduct(object)
            if(product && hasPositionChanged) {
                setCustomStoreEntityProps(product, {forceShowPositionChanged: true})
            }
            //console.log('showing', object?.custom?.forceShowPositionChanged)
            setPositionChanged(object?.custom?.forceShowPositionChanged)
            handleStoreChange()
            productRef.current = getObjectPosition(object)
        }
    }, [store.activePage?.id])

    useEffect(() => {
        const beforeUnloadHandler = (e: any) => {
            if (isProjectSaving) {
                e.returnValue = t('pages.editor.savingInProgress')
                return t('pages.editor.savingInProgress')
            }
        }
        window.addEventListener('beforeunload', beforeUnloadHandler)
        return () => window.removeEventListener('beforeunload', beforeUnloadHandler)
    }, [isProjectSaving])

    useEffect(() => {  reachGoal(`visit_${store.openedSidePanel}_panel`)}, [store.openedSidePanel])
    const ImageRemoveBackground = () => null;
    return (
        <PolotnoContainer className="fullHW">
            <LoadingModal active={isInit} backgroundRemoved={backgroundRemoved} />
            <SidePanelWrap className="sidePanelWrap">
                <CustomSidePanel store={store} sections={sections} defaultSection={
                    isRuLocale()? InfographicsTool.name : AiBackgroundTool.name } />
            </SidePanelWrap>
            <WorkspaceWrap>
                <Toolbar
                    store={store}
                    downloadButtonEnabled
                    components={{ActionControls: DownloadButton, ImageRemoveBackground}}
                />
                <Workspace
                    backgroundColor="rgb(240 240 245)"
                    store={store}
                    components={{
                        Image: ReplaceObjectButton,
                        PageControls: () => null
                    }}
                />
                <ZoomButtons store={store} />
                {hasPremiumSubscription && <PagesTimeline store={store} />}
            </WorkspaceWrap>
            <ModalDialog
                name={'ReplaceObject'}
                ContainerComponent={ReplaceObjectModalDialogComponent}
                render={(p) => <ReplaceObject {...p} />}
            />
        </PolotnoContainer>
    )
})