import { axiosInstance } from '../axiosConfig'
import type { ProjectObject } from '../../domain/types/Project'
import { backendUrl } from '../../config/app'
import { mapProjectToObject } from './mappers'

export const updateProject = async (
  id: string,
  state: any,
  meta: any = null
): Promise<ProjectObject> => {
  const data = {
    meta,
    state,
  }
  const response = await axiosInstance.patch(`${backendUrl()}api/ngb/projects/${id}`, data)

  return mapProjectToObject(response.data)
}
