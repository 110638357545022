import { axiosInstance } from '../axiosConfig'
import type { ProjectObject } from '../../domain/types/Project'
import { backendUrl } from '../../config/app'
import { mapProjectToObject } from './mappers'

export const fetchProject = async (id: string): Promise<ProjectObject> => {
  const response = await axiosInstance.get(`${backendUrl()}api/ngb/projects/${id}`)

  if (response.status === 404) {
    throw new Error('Project not found')
  }

  return mapProjectToObject(response.data)
}
