import { resizeImage } from "../../../utils/resizeImage";
import { reachGoal } from "../../../utils/metrics";
import { createProjectId } from "../../../logger/logEvent";
import { backendUrl } from "../../../config/app";
import { axiosInstance } from "../../../api/axiosConfig";

export const onFileDrop = async (files: File[], removeBackground: boolean) => {
  const resizedFile = await resizeImage(files[0], 2400)
  reachGoal('upload_image')
  const pid = createProjectId()
  const formData = new FormData()
  formData.append('image', resizedFile)
  formData.append('connectionString', 'connectionString')
  try {
    const uploadImagResponse = await axiosInstance.post(
      `${backendUrl()}api/image/upload?pid=${pid}&removeBackground=${removeBackground}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    const image = uploadImagResponse.data
    return image
  } catch (e) {
    console.error('Error upload image and create project', e)
  }
}