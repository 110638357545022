import axios from 'axios';
import { R_SIGN_IN_PAGE } from '../router-constants';

export const axiosInstance = axios.create()

axiosInstance.interceptors.response.use(
  response => response, 
  error => {
    if (error.response && error.response.status === 401) {
      window.location.href = R_SIGN_IN_PAGE;
    } else if (error.response && error.response.status === 500) {
      console.error('Server error. Try again later.')
    }

    return Promise.reject(error)
  }
)