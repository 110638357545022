import './aitemplate.css'
import {reachGoal} from "../../../utils/metrics";
import {usePolotno} from "../../../store/usePolotno";
import { useTranslation } from 'react-i18next'
import {setCustomStoreEntityProps} from "../utils/image.utils";

export const templateCategories = [
  {
    name: 'backgroundTemplates.atmosphereCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.bokehEffectTemplate',
        image: '/background_templates/at_0.jpg',
        prompt:
          'a professional photo of a product with a bokeh background, lighting in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: '0',
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.minimalismTemplate',
        image: '/background_templates/at_1.jpg',
        prompt:
          'a professional photo of a product with a minimalist background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 1,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.flowerTemplate',
        image: '/background_templates/at_2.jpg',
        prompt: 'a professional photo of a product among flowers, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 2,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.winterTemplate',
        image: '/background_templates/at_3.jpg',
        prompt:
          'a professional photo of a product with snow, winter background, winter street, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 3,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.autumnTemplate',
        image: '/background_templates/at_4.jpg',
        prompt:
          'a professional photo of a product with an autumn theme, autumn background, autumn street, autumn park, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 4,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.beachTemplate',
        image: '/background_templates/at_5.jpg',
        prompt:
          'a professional photo of a product at a tropical beach with a blue sea, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 5,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.mountainTemplate',
        image: '/background_templates/at_6.jpg',
        prompt:
          'a professional photo of a product with a mountain view, a product in the mountains, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 6,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.stumpTemplate',
        image: '/background_templates/at_7.jpg',
        prompt:
          'a professional photo of a product on a tree stump surrounded by herbs and ferns, in front of the marsh and the forest in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 7,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.cloudTemplate',
        image: '/background_templates/at_8.jpg',
        prompt:
          'a professional photo of a product placed on beautiful clouds, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 8,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.sunsetTemplate',
        image: '/background_templates/at_9.jpg',
        prompt:
          'a professional photo of a product at sunset outside, nature in the background, windowsill, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 9,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.goldenHourTemplate',
        image: '/background_templates/at_10.jpg',
        prompt:
          'a professional photo of a product with golden hour in the background, sun and shadows, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 10,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.streetTemplate',
        image: '/background_templates/at_11.jpg',
        prompt:
          'a professional photo of a product in a street, a park, the avenue, the yard, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 11,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.romanticTemplate',
        image: '/background_templates/at_12.jpg',
        prompt:
          'a professional photo of a product with hearts, love theme, romantic theme, hearts in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 12,
      },
    ],
  },
  {
    name: 'backgroundTemplates.holidaysCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.holidaysCategory.templates.birthdayTemplate',
        key: 13,
        image: '/background_templates/pr_0.jpg',
        prompt:
          'a professional photo of a product with a birthday theme, award winning photography',
        negativePrompt:
          'human, person, child, face, letters, text, oversaturated, ugly, 3d, render, cartoon, grain, low-res, lens, camera',
      },
      {
        name: 'backgroundTemplates.holidaysCategory.templates.weddingTemplate',
        key: 14,
        image: '/background_templates/pr_1.jpg',
        prompt:
          'a professional photo of a product with a wedding theme, wedding decorations, award winning photography',
        negativePrompt:
          'human, person, child, face, letters, text, oversaturated, ugly, 3d, render, cartoon, grain, low-res, lens, camera',
      },
      {
        name: 'backgroundTemplates.holidaysCategory.templates.genderPartyTemplate',
        key: 15,
        image: '/background_templates/pr_2.jpg',
        prompt:
          'a professional photo of a product with a baby shower theme, baby shower event decorations, gender party decorations, pink and blue, award winning photography',
        negativePrompt:
          'human, person, child, face, letters, text, oversaturated, ugly, 3d, render, cartoon, grain, low-res, lens, camera',
      },
      {
        name: 'backgroundTemplates.holidaysCategory.templates.easterTemplate',
        key: 16,
        image: '/background_templates/pr_3.jpg',
        prompt:
          'a professional photo of a product on a grassy field, surrounded by pastel easter eggs, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.holidaysCategory.templates.newYearTemplate',
        key: 17,
        image: '/background_templates/pr_4.jpg',
        prompt:
          'a professional photo of a product with a christmas theme, christmas decorations, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.tablesCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.tablesCategory.templates.lightWoodTemplate',
        key: 18,
        image: '/background_templates/st_0.jpg',
        prompt:
          'a professional photo of a product on a table made of light Wood, light wood countertop, hd light Wood wallpaper, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.tablesCategory.templates.darkWoodTemplate',
        key: 19,
        image: '/background_templates/st_1.jpg',
        prompt:
          'a professional photo of a product on a table made of dark Wood, dark wood countertop, hd dark Wood wallpaper, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.tablesCategory.templates.redWoodTemplate',
        key: 20,
        image: '/background_templates/st_2.jpg',
        prompt:
          'a professional photo of a product on a table made of dark red Wood, dark red wood countertop, hd dark red Wood wallpaper, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.tablesCategory.templates.concreteTemplate',
        key: 21,
        image: '/background_templates/st_3.jpg',
        prompt:
          'a professional photo of a product on a table made of Concrete, Concrete countertop, hd Concrete wallpaper, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.tablesCategory.templates.whiteMarbleTemplate',
        key: 22,
        image: '/background_templates/st_4.jpg',
        prompt:
          'a professional photo of a product on a table made of white Marble, white Marble countertop, hd white Marble wallpaper, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.tablesCategory.templates.blackMarbleTemplate',
        key: 23,
        image: '/background_templates/st_5.jpg',
        prompt:
          'a professional photo of a product on a table made of black Marble, black Marble countertop, hd black Marble wallpaper, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.tablesCategory.templates.brownMarbleTemplate',
        key: 24,
        image: '/background_templates/st_6.jpg',
        prompt:
          'a professional photo of a product on a table made of brown Marble, brown Marble countertop, hd brown Marble wallpaper, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.tablesCategory.templates.creamMarbleTemplate',
        key: 25,
        image: '/background_templates/st_7.jpg',
        prompt:
          'a professional photo of a product on a table made of cream Marble, cream Marble countertop, hd cream Marble wallpaper, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.tablesCategory.templates.greenMarbleTemplate',
        key: 26,
        image: '/background_templates/st_8.jpg',
        prompt:
          'a professional photo of a product on a table made of green Marble, green Marble countertop, hd green Marble wallpaper, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.plantsCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.plantsCategory.templates.succulentTemplate',
        key: 27,
        image: '/background_templates/pl_0.jpg',
        prompt:
          'a professional photo of a product with Succulents around, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.plantsCategory.templates.monsteraTemplate',
        key: 28,
        image: '/background_templates/pl_1.jpg',
        prompt:
          'a professional photo of a product with Monstera Plants around, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.plantsCategory.templates.cactusTemplate',
        key: 29,
        image: '/background_templates/pl_2.jpg',
        prompt: 'a professional photo of a product with Cactil around, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.plantsCategory.templates.tillandsiaTemplate',
        key: 30,
        image: '/background_templates/pl_3.jpg',
        prompt:
          'a professional photo of a product with Air Plants and Tillandsia around, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.plantsCategory.templates.hoyaTemplate',
        key: 31,
        image: '/background_templates/pl_4.jpg',
        prompt:
          'a professional photo of a product with Hoya Plants around, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.plantsCategory.templates.pileaTemplate',
        key: 32,
        image: '/background_templates/pl_5.jpg',
        prompt:
          'a professional photo of a product with Pilea Plants around, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.interiorCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.interiorCategory.templates.cozyHomeTemplate',
        key: 33,
        image: '/background_templates/in_0.jpg',
        prompt:
          'a professional photo of a product in a cosy home, furniture, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.interiorCategory.templates.bathroomTemplate',
        key: 34,
        image: '/background_templates/in_1.jpg',
        prompt:
          'a professional photo of a product on a bathroom counter, in front of luxurious bathroom, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.interiorCategory.templates.kitchenTemplate',
        key: 35,
        image: '/background_templates/in_2.jpg',
        prompt:
          'a professional photo of a product placed on a countertop with a blurred kitchen in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.interiorCategory.templates.kidsRoomTemplate',
        key: 36,
        image: '/background_templates/in_3.jpg',
        prompt:
          "a professional photo of a product placed on a countertop with a blurred children's room in the background, playroom, award winning photography",
        negativePrompt:
          'human, person, child, face, letters, text, oversaturated, ugly, 3d, render, cartoon, grain, low-res, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.foodCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.foodCategory.templates.grapeTemplate',
        key: 37,
        image: '/background_templates/fo_0.jpg',
        prompt:
          'a professional photo of a product on a marble countertop surrounded by grapes, with a vineyard in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.foodCategory.templates.coffeeTemplate',
        key: 38,
        image: '/background_templates/fo_1.jpg',
        prompt:
          'a professional photo of a product on a wooden table surrounded by coffee beans, in front of a luxury cafe, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.foodCategory.templates.berryTemplate',
        key: 39,
        image: '/background_templates/fo_2.jpg',
        prompt:
          'a professional photo of a product on a table next to bowls of berries, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.flowersCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.flowersCategory.templates.rosesTemplate',
        key: 40,
        image: '/background_templates/fl_0.jpg',
        prompt: 'a professional photo of a product with Roses flowers, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.flowersCategory.templates.tulipsTemplate',
        key: 41,
        image: '/background_templates/fl_1.jpg',
        prompt: 'a professional photo of a product with Tulips flowers, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.flowersCategory.templates.lavenderTemplate',
        key: 42,
        image: '/background_templates/fl_2.jpg',
        prompt:
          'a professional photo of a product with Lavender flowers, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.flowersCategory.templates.cherryBlossomTemplate',
        key: 43,
        image: '/background_templates/fl_3.jpg',
        prompt:
          'a professional photo of a product with Cherry Blossom flowers, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.texturesCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.texturesCategory.templates.soilTemplate',
        key: 44,
        image: '/background_templates/te_0.jpg',
        prompt: 'a professional photo of a product with soil, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.texturesCategory.templates.waterTemplate',
        key: 45,
        image: '/background_templates/te_1.jpg',
        prompt:
          'a professional photo of a product on a liquid surface, wet surface, water, water splashes, hd wallpaper, ripples, studio lighting, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.texturesCategory.templates.fabricTemplate',
        key: 46,
        image: '/background_templates/te_2.jpg',
        prompt: 'a professional photo of a product with fabric, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.texturesCategory.templates.marbleTemplate',
        key: 47,
        image: '/background_templates/te_3.jpg',
        prompt:
          'a professional photo of a product on a marble table, elegant, luxury, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.citiesCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.citiesCategory.templates.newYorkTemplate',
        key: 48,
        image: '/background_templates/ci_0.jpg',
        prompt:
          'a professional photo of a product in front of a big window, New York in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.citiesCategory.templates.parisTemplate',
        key: 49,
        image: '/background_templates/ci_1.jpg',
        prompt:
          'a professional photo of a product in front of a big window, Paris in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.citiesCategory.templates.marrakechTemplate',
        key: 50,
        image: '/background_templates/ci_2.jpg',
        prompt:
          'a professional photo of a product in front of a big window, Marrakech in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.citiesCategory.templates.mykonosTemplate',
        key: 51,
        image: '/background_templates/ci_3.jpg',
        prompt:
          'a professional photo of a product in front of a big window, Mykonos in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.citiesCategory.templates.veniceTemplate',
        key: 52,
        image: '/background_templates/ci_4.jpg',
        prompt:
          'a professional photo of a product in front of a big window, Venice in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.citiesCategory.templates.okinawaTemplate',
        key: 53,
        image: '/background_templates/ci_5.jpg',
        prompt:
          'a professional photo of a product in front of a big window, Okinawa in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.citiesCategory.templates.baliTemplate',
        key: 54,
        image: '/background_templates/ci_6.jpg',
        prompt:
          'a professional photo of a product in front of a big window, Bali in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.natureCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.natureCategory.templates.oceanTemplate',
        key: 55,
        image: '/background_templates/na_0.jpg',
        prompt:
          'a professional photo of a product in front of a big window, the Ocean in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.natureCategory.templates.desertTemplate',
        key: 56,
        image: '/background_templates/na_1.jpg',
        prompt:
          'a professional photo of a product in front of a big window, the Sand Desert in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.natureCategory.templates.forestTemplate',
        key: 57,
        image: '/background_templates/na_2.jpg',
        prompt:
          'a professional photo of a product in front of a big window, the Pine Forest in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.natureCategory.templates.mountainsTemplate',
        key: 58,
        image: '/background_templates/na_3.jpg',
        prompt:
          'a professional photo of a product in front of a big window, the Mountains in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.fantasyCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.fantasyCategory.templates.spaceshipTemplate',
        key: 59,
        image: '/background_templates/fa_0.jpg',
        prompt:
          'a professional photo of a product in an outer space facility, above earth, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.fantasyCategory.templates.moonTemplate',
        key: 60,
        image: '/background_templates/fa_1.jpg',
        prompt:
          'a professional image of a product on a planet that was once an overcast moon, star studded sky in the background, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.fantasyCategory.templates.spaceTemplate',
        key: 61,
        image: '/background_templates/fa_2.jpg',
        prompt:
          'a professional image of a product hovering in deep space, stars and nebula in the far distance, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.fantasyCategory.templates.marsTemplate',
        key: 62,
        image: '/background_templates/fa_3.jpg',
        prompt:
          'a professional image of a product in an alien desert landscape, award winning photography',
        negativePrompt:
          'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
]

export const TemplateCategory = ({
  name,
  templates,
  activeBackgroundKey,
  generateBackground,
  setActiveBackgroundKey,
  isStartAnimationOnGenerationButton,
}: {
  name: string
  templates: any
  activeBackgroundKey: any
  generateBackground: (
    prompt: string,
    negativePrompt: string,
    name?: string,
    selectAiService?: boolean
  ) => void
  setActiveBackgroundKey: any
  isStartAnimationOnGenerationButton: any
}) => {
  const { t } = useTranslation()
  return (
    <div className="tool-panel_content_category">
      <h3 className="bodyM colorTextBlackPrimary" style={{ color: 'rgba(22, 27, 51, 0.6)' }}>
        {t(name)}
      </h3>
      <div className="tool-panel_content_category_wrapper mobile-flex-start">
        {templates.map((template: any, index: number) => (
          <AITemplate
            key={index}
            name={template.name}
            prompt={template.prompt}
            negativePrompt={template.negativePrompt}
            activeBackgroundKey={activeBackgroundKey}
            image={template.image}
            generateBackground={generateBackground}
            setActiveBackgroundKey={setActiveBackgroundKey}
            activeKey={template.key}
            isStartAnimationOnGenerationButton={isStartAnimationOnGenerationButton}
          />
        ))}
      </div>
    </div>
  )
}

export const AITemplate = ({
  name,
  activeKey,
  prompt,
  negativePrompt,
  image,
  activeBackgroundKey,
  generateBackground,
  setActiveBackgroundKey,
  isStartAnimationOnGenerationButton,
}: {
  name: string
  activeKey: number
  prompt: string
  negativePrompt: string
  image: string
  activeBackgroundKey: any
  generateBackground: (
    prompt: string,
    negativePrompt: string,
    name?: string,
    selectAiService?: boolean
  ) => void
  setActiveBackgroundKey: any
  isStartAnimationOnGenerationButton: any
}) => {
  const store = usePolotno(s => s.store)
  const { t } = useTranslation()
  return (
    <div className="tool-panel_content_template">
      <div
        className={
          activeBackgroundKey == activeKey
            ? 'tool-panel_content_template_image_block active'
            : 'tool-panel_content_template_image_block'
        }
      >
        <img
          className="tool-panel_content_template_image"
          src={image}
          alt={t(name)}
          onClick={() => {
            setCustomStoreEntityProps(store, { currentPromptInStore: {prompt,negativePrompt}})
            reachGoal('click_template_background')
            setActiveBackgroundKey(activeKey)
            isStartAnimationOnGenerationButton()
          }}
        />
      </div>
      <span className="bodyS colorTextBlackSecondary center">{t(name)}</span>
    </div>
  )
}
