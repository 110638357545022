import type { Feature } from './cards/CardPlanRedesign'

export const REDESIGN_FEATURES: {
  base: Feature[]
  premium: Feature[]
  business: Feature[]
} = {
  base: [
    { label: 'storeUpToCards_ab', quantity: 10, active:true, hint: 'Вы можете создать и хранить до 10 проектов одновременно.' },
    { label: 'AIGenerationsPerMonth_ab', quantity: 250, active:true },
    { label: 'unlimitedCardDownloads', active:true },
    { label: 'backgroundRemoval_ab', active:true },
    { label: 'infographicEditor_ab', active:true, hint: 'Редактор инфографики позволяет адаптировать шаблоны под ваш стиль и предпочтения. Создавайте карточки, которые идеально соответствуют вашему товару.' },
    { label: 'addImages_ab', active:true },
    { label: 'limitedInfographicTemplates_ab', disadvantage: true, active:true },
    { label: 'photoFunnel_ab', active:false },
    { label: 'cardSizeAdaptation', active:false },
  ],
  premium: [
    { label: 'storeUpToCards_ab', quantity: 100, active:true, hint: 'Вы можете создать и хранить до 100 проектов одновременно.'},
    { label: 'AIGenerationsPerMonth_ab', quantity: 2500, active:true },
    { label: 'unlimitedCardDownloads', active:true },
    { label: 'backgroundRemoval_ab', active:true },
    { label: 'infographicEditor_ab', active:true, hint: 'Редактор инфографики позволяет адаптировать шаблоны под ваш стиль и предпочтения. Создавайте карточки, которые идеально соответствуют вашему товару.' },
    { label: 'addImages_ab', active:true },
    { label: 'allInfographicTemplates_ab', active:true },
    { label: 'photoFunnel_ab', active:true, hint:'Создайте карточку с несколькими слайдами за секунды! Загрузите фото — каждый снимок станет слайдом. Выберите фон и шаблон для идеального оформления.' },
    { label: 'cardSizeAdaptation', active:true, hint: 'Адаптация размера карточек позволяет выбрать оптимальный формат (3:4 или 1:1) для нужных маркетплейсов. Все настройки сохранятся и автоматически подстроятся под выбранный размер.' },
  ],
  business: [
    { label: 'unlimitedStorage_ab', active:true },
    { label: 'unlimitedAIGenerations_ab', active:true },
    { label: 'unlimitedCardDownloadsAccented_ab', active:true},
    { label: 'backgroundRemoval_ab', active:true },
    { label: 'infographicEditor_ab', active:true, hint: 'Редактор инфографики позволяет адаптировать шаблоны под ваш стиль и предпочтения. Создавайте карточки, которые идеально соответствуют вашему товару.' },
    { label: 'addImages_ab', active:true },
    { label: 'allInfographicTemplates_ab', active:true },
    { label: 'photoFunnel_ab', active:true, hint: 'Создайте карточку с несколькими слайдами за секунды! Загрузите фото — каждый снимок станет слайдом. Выберите фон и шаблон для идеального оформления.' },
    { label: 'cardSizeAdaptation', active:true, hint: 'Адаптация размера карточек позволяет выбрать оптимальный формат (3:4 или 1:1) для нужных маркетплейсов. Все настройки сохранятся и автоматически подстроятся под выбранный размер.' },
    { label: 'personalSupportManager_ab', active: true},
  ],
}

export const FULL_FEATURES: {
  base: Feature[]
  premium: Feature[]
  business: Feature[]
  demo: Feature[]
} = {
  base: [
    { label: 'storeUpToCards', quantity: 10 },
    { label: 'AIGenerationsPerMonth', quantity: 250 },
    { label: 'unlimitedCardDownloads' },
    { label: 'infographicEditor_ab' },
    { label: 'standardSupport' },
    { label: 'InfographicTemplates', disadvantage: true, quantity: 3 },
  ],
  premium: [
    { label: 'storeUpToCards', quantity: 100 },
    { label: 'AIGenerationsPerMonth', quantity: 2500 },
    { label: 'unlimitedCardDownloads' },
    { label: 'allInfographicTemplates' },
    { label: 'cardSizeAdaptation' },
    { label: 'photoFunnelWithSlides' },
    { label: 'prioritySupport247' },
  ],
  business: [
    { label: 'unlimitedStorage' },
    { label: 'unlimitedAIGenerations' },
    { label: 'allInfographicTemplates' },
    { label: 'cardSizeAdaptation' },
    { label: 'photoFunnelWithSlides' },
    { label: 'personalSupportManager' },
  ],
  demo: [
    {label: '250generations'},
    {label: 'allFeatures'}
  ]
}
